import classnames from 'classnames';
import PropTypes from 'prop-types';

const SendSecond1Icon = ({ width, height, fill, customClassNames }) => {
  const className = 'c-SendSecond1Icon';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      className={classnames(className, customClassNames)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8839 1.46978C16.5871 1.37062 16.3107 1.44233 16.1734 1.48223C16.0236 1.52573 15.8452 1.5954 15.6662 1.6653L2.94833 6.62835C2.7482 6.70642 2.55438 6.78203 2.40477 6.856C2.2755 6.91992 2.00075 7.06218 1.84739 7.35739C1.67826 7.68294 1.67849 8.0705 1.848 8.39585C2.00171 8.69087 2.27663 8.83281 2.40597 8.89658C2.55567 8.97038 2.74958 9.04576 2.9498 9.12359L7.98323 11.081L9.94069 16.1145C10.0185 16.3147 10.0939 16.5086 10.1677 16.6583C10.2315 16.7876 10.3734 17.0626 10.6684 17.2163C10.9938 17.3858 11.3813 17.386 11.7069 17.2169C12.0021 17.0635 12.1443 16.7888 12.2083 16.6595C12.2822 16.5099 12.3578 16.3161 12.4359 16.116L17.399 3.3981C17.4689 3.21907 17.5385 3.04063 17.582 2.89091C17.6219 2.75352 17.6937 2.47717 17.5945 2.18035C17.4825 1.84499 17.2193 1.58181 16.8839 1.46978ZM9.43901 10.6859L15.1885 4.93646L11.1888 15.1855L9.43901 10.6859ZM14.1278 3.8758L8.37835 9.62526L3.87878 7.87542L14.1278 3.8758ZM17.4032 17.0919C17.2972 17.1984 17.163 17.2516 17.0005 17.2516H14.938C14.7255 17.2516 14.5472 17.1796 14.4032 17.0356C14.2597 16.8921 14.188 16.7141 14.188 16.5016V15.3766C14.188 15.1641 14.2597 14.9859 14.4032 14.8419C14.5472 14.6984 14.7255 14.6266 14.938 14.6266H16.438V13.8766H14.7505C14.588 13.8766 14.4535 13.8234 14.347 13.7169C14.241 13.6109 14.188 13.4766 14.188 13.3141C14.188 13.1516 14.241 13.0171 14.347 12.9106C14.4535 12.8046 14.588 12.7516 14.7505 12.7516H16.813C17.0255 12.7516 17.2035 12.8234 17.347 12.9669C17.491 13.1109 17.563 13.2891 17.563 13.5016V14.6266C17.563 14.8391 17.491 15.0171 17.347 15.1606C17.2035 15.3046 17.0255 15.3766 16.813 15.3766H15.313V16.1266H17.0005C17.163 16.1266 17.2972 16.1796 17.4032 16.2856C17.5097 16.3921 17.563 16.5266 17.563 16.6891C17.563 16.8516 17.5097 16.9859 17.4032 17.0919Z"
        fill={fill}
      />
    </svg>
  );
};

SendSecond1Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  customClassNames: PropTypes.string
};
SendSecond1Icon.defaultProps = {
  width: '19',
  height: '19',
  fill: '#30A66D',
  customClassNames: ''
};

export default SendSecond1Icon;
