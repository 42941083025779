import React from 'react';

const ErrorAlertWarning = ({ width, height, fill }) => {
  return (
    <svg
      className="c-ErrorAlertWarning"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"></path>
    </svg>
  );
};

ErrorAlertWarning.defaultProps = {
  width: 24,
  height: 24,
  fill: '#946800'
};

export default ErrorAlertWarning;
