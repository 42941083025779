import React from 'react';

const MessageXSquare = () => {
  return (
    <svg width="40" height="40" fill="none" viewBox="0 0 40 40">
      <path
        fill="#8F99EF"
        fillRule="evenodd"
        d="M12.93 3.334h14.138c1.341 0 2.449 0 3.35.074.937.076 1.799.24 2.608.653a6.667 6.667 0 012.913 2.913c.412.809.576 1.67.653 2.607.074.902.074 2.01.074 3.35v9.139c0 1.341 0 2.448-.074 3.35-.077.937-.24 1.798-.653 2.607a6.667 6.667 0 01-2.913 2.914c-.81.412-1.67.576-2.607.653-.902.073-2.01.073-3.351.073h-4.263c-1.107 0-1.43.008-1.722.068a3.33 3.33 0 00-.847.297c-.266.137-.523.332-1.387 1.023l-4.022 3.218a13.25 13.25 0 01-.893.676c-.262.173-.77.484-1.432.485a2.5 2.5 0 01-1.957-.94c-.413-.518-.488-1.11-.517-1.422-.03-.328-.03-.728-.03-1.12v-2.298c-.652-.022-1.212-.076-1.725-.214a6.667 6.667 0 01-4.714-4.714c-.228-.853-.228-1.837-.227-3.163V12.932c0-1.342 0-2.45.074-3.351.076-.937.24-1.798.653-2.607a6.667 6.667 0 012.913-2.913c.81-.413 1.67-.577 2.607-.653.902-.074 2.01-.074 3.351-.074zM9.85 6.73c-.73.06-1.104.168-1.364.3-.628.32-1.138.83-1.457 1.457-.133.261-.241.635-.3 1.365-.062.75-.063 1.721-.063 3.149v10.333c0 1.657.014 2.159.113 2.53a3.333 3.333 0 002.357 2.356c.37.1.872.114 2.53.114.92 0 1.666.746 1.666 1.667V33.2l3.434-2.748.113-.09c.703-.563 1.235-.988 1.84-1.298a6.67 6.67 0 011.694-.594c.666-.137 1.347-.136 2.248-.136H27c1.428 0 2.398-.001 3.148-.063.73-.06 1.105-.168 1.365-.3a3.334 3.334 0 001.457-1.457c.133-.26.24-.634.3-1.365.062-.75.063-1.72.063-3.148v-9c0-1.428-.001-2.399-.062-3.149-.06-.73-.168-1.104-.301-1.365a3.333 3.333 0 00-1.457-1.456c-.26-.133-.634-.241-1.365-.301-.75-.061-1.72-.063-3.148-.063h-14c-1.428 0-2.398.002-3.148.063zm4.804 5.425c.65-.65 1.706-.65 2.357 0l2.988 2.989 2.988-2.989a1.667 1.667 0 112.357 2.357l-2.988 2.989 2.988 2.988a1.667 1.667 0 11-2.357 2.357l-2.988-2.988-2.988 2.988a1.667 1.667 0 11-2.357-2.357l2.988-2.988-2.988-2.989a1.667 1.667 0 010-2.357z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default MessageXSquare;
