import React from 'react';

const Tick = ({
  customClassNames,
  fill = '#F5F5F5',
  width = 14,
  height = 14
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      className={customClassNames}>
      <path
        d="M5.08366 10.2864L2.54045 7.66458C2.4078 7.52814 2.33325 7.34289 2.33325 7.14971C2.33325 6.95653 2.4078 6.77129 2.54045 6.63485C2.81673 6.35003 3.26303 6.35003 3.53932 6.63485L5.58663 8.73813L10.4605 3.71362C10.7368 3.42879 11.1831 3.42879 11.4594 3.71362C11.7357 3.99844 11.7357 4.45853 11.4594 4.74335L6.08252 10.2864C5.81332 10.5712 5.35994 10.5712 5.08366 10.2864Z"
        fill={fill}
      />
    </svg>
  );
};

export default Tick;
