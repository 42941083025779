import React from 'react';

const PlusCircle = ({ customClassNames, height, width, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={customClassNames}>
      <g clipPath="url(#clip0_2958_27597)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00008 1.99984C4.68637 1.99984 2.00008 4.68613 2.00008 7.99984C2.00008 11.3135 4.68637 13.9998 8.00008 13.9998C11.3138 13.9998 14.0001 11.3135 14.0001 7.99984C14.0001 4.68613 11.3138 1.99984 8.00008 1.99984ZM0.666748 7.99984C0.666748 3.94975 3.94999 0.666504 8.00008 0.666504C12.0502 0.666504 15.3334 3.94975 15.3334 7.99984C15.3334 12.0499 12.0502 15.3332 8.00008 15.3332C3.94999 15.3332 0.666748 12.0499 0.666748 7.99984ZM8.00008 4.6665C8.36827 4.6665 8.66675 4.96498 8.66675 5.33317V7.33317H10.6667C11.0349 7.33317 11.3334 7.63165 11.3334 7.99984C11.3334 8.36803 11.0349 8.6665 10.6667 8.6665H8.66675V10.6665C8.66675 11.0347 8.36827 11.3332 8.00008 11.3332C7.63189 11.3332 7.33341 11.0347 7.33341 10.6665V8.6665H5.33341C4.96522 8.6665 4.66675 8.36803 4.66675 7.99984C4.66675 7.63165 4.96522 7.33317 5.33341 7.33317H7.33341V5.33317C7.33341 4.96498 7.63189 4.6665 8.00008 4.6665Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2958_27597">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PlusCircle.defaultProps = {
  fill: '#1B1B18',
  height: '16',
  width: '16',
  customClassNames: ''
};

export default PlusCircle;
