import React from 'react';

function Add({ width = 18, height = 18, fill = '#F5F5F5' }) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 18 18">
      <path
        fill={fill}
        d="M13.5 9.75H9.75v3.75c0 .412-.338.75-.75.75a.752.752 0 01-.75-.75V9.75H4.5A.752.752 0 013.75 9c0-.412.338-.75.75-.75h3.75V4.5c0-.412.338-.75.75-.75s.75.338.75.75v3.75h3.75c.412 0 .75.338.75.75s-.338.75-.75.75z"></path>
    </svg>
  );
}

export default Add;
