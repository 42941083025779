import React from 'react';

const CheckIcon = ({ strokeColor = '#12978F' }) => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
      <path
        d="M0.75 4.75L4.25 8.25L11.25 0.75"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
