import React from 'react';

const NPLCourse = ({ customClassNames, customInnerClassNames }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={`c-NPLCourse ${customClassNames}`}>
      <path
        d="M8.66669 3.33219C8.28891 3.33219 7.97202 3.20419 7.71602 2.94819C7.46091 2.69308 7.33335 2.37664 7.33335 1.99886C7.33335 1.62108 7.46091 1.30419 7.71602 1.04819C7.97202 0.793083 8.28891 0.665527 8.66669 0.665527H19.3334C19.7111 0.665527 20.0276 0.793083 20.2827 1.04819C20.5387 1.30419 20.6667 1.62108 20.6667 1.99886C20.6667 2.37664 20.5387 2.69308 20.2827 2.94819C20.0276 3.20419 19.7111 3.33219 19.3334 3.33219H8.66669ZM4.66669 7.33219C4.28891 7.33219 3.97202 7.20464 3.71602 6.94953C3.46091 6.69353 3.33335 6.37664 3.33335 5.99886C3.33335 5.62108 3.46091 5.30419 3.71602 5.04819C3.97202 4.79308 4.28891 4.66553 4.66669 4.66553H23.3334C23.7111 4.66553 24.0276 4.79308 24.2827 5.04819C24.5387 5.30419 24.6667 5.62108 24.6667 5.99886C24.6667 6.37664 24.5387 6.69353 24.2827 6.94953C24.0276 7.20464 23.7111 7.33219 23.3334 7.33219H4.66669ZM3.33335 27.3322C2.60002 27.3322 1.97246 27.0713 1.45069 26.5495C0.92802 26.0269 0.666687 25.3989 0.666687 24.6655V11.3322C0.666687 10.5989 0.92802 9.97086 1.45069 9.44819C1.97246 8.92642 2.60002 8.66553 3.33335 8.66553H24.6667C25.4 8.66553 26.028 8.92642 26.5507 9.44819C27.0725 9.97086 27.3334 10.5989 27.3334 11.3322V24.6655C27.3334 25.3989 27.0725 26.0269 26.5507 26.5495C26.028 27.0713 25.4 27.3322 24.6667 27.3322H3.33335ZM3.33335 24.6655H24.6667V11.3322H3.33335V24.6655ZM13.4 21.9655L17.6667 19.0989C18.0667 18.8322 18.2667 18.4655 18.2667 17.9989C18.2667 17.5322 18.0667 17.1655 17.6667 16.8989L13.4 14.0322C12.9556 13.7211 12.5 13.6989 12.0334 13.9655C11.5667 14.2322 11.3334 14.6322 11.3334 15.1655V20.8322C11.3334 21.3655 11.5667 21.7655 12.0334 22.0322C12.5 22.2989 12.9556 22.2766 13.4 21.9655Z"
        fill="#706F6B"
        className={customInnerClassNames}
      />
    </svg>
  );
};

export default NPLCourse;
