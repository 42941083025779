import React from 'react';

const ChevronDown = ({ fill = '#1B1B18' }) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={fill}
        d="M10.584 6L7.997 8.586 5.41 6a.664.664 0 10-.94.94L7.53 10c.26.26.68.26.94 0l3.06-3.06c.26-.26.26-.68 0-.94a.678.678 0 00-.946 0z"></path>
    </svg>
  );
};

export default ChevronDown;
