import React from 'react';

const Publish = ({ customClassNames, fill = '#1B1B18' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={customClassNames}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93692 5.35727C4.5474 3.13362 6.5823 1.5 9 1.5C11.4177 1.5 13.4526 3.13362 14.0631 5.35727C15.8896 5.78221 17.25 7.41937 17.25 9.375C17.25 10.8059 16.5208 12.0662 15.4172 12.8049C15.073 13.0353 14.6072 12.9431 14.3767 12.5989C14.1463 12.2546 14.2386 11.7888 14.5828 11.5584C15.2882 11.0862 15.75 10.2844 15.75 9.375C15.75 8.00834 14.705 6.88488 13.371 6.76133C13.0312 6.72986 12.7555 6.47294 12.7001 6.13619C12.4077 4.35726 10.8616 3 9 3C7.13838 3 5.59232 4.35726 5.29987 6.13619C5.24451 6.47294 4.96879 6.72986 4.62897 6.76133C3.29497 6.88488 2.25 8.00834 2.25 9.375C2.25 10.2844 2.71181 11.0862 3.41719 11.5584C3.76141 11.7888 3.85367 12.2546 3.62326 12.5989C3.39285 12.9431 2.92702 13.0353 2.58281 12.8049C1.47921 12.0662 0.75 10.8059 0.75 9.375C0.75 7.41937 2.11043 5.78221 3.93692 5.35727ZM8.25 10.8107L6.53033 12.5303C6.23744 12.8232 5.76256 12.8232 5.46967 12.5303C5.17678 12.2374 5.17678 11.7626 5.46967 11.4697L8.46967 8.46967C8.76256 8.17678 9.23744 8.17678 9.53033 8.46967L12.5303 11.4697C12.8232 11.7626 12.8232 12.2374 12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303L9.75 10.8107V15.75C9.75 16.1642 9.41421 16.5 9 16.5C8.58579 16.5 8.25 16.1642 8.25 15.75V10.8107Z"
        fill={fill}
        fillOpacity="0.65"
      />
    </svg>
  );
};

export default Publish;
