import React from 'react';

const DollarIconGrey = ({
  width,
  height,
  customClassNames,
  customInnerClassNames,
  fill = '#706F6B'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 30"
      fill="none"
      className={`c-DollarIconGrey ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 0.333252C10.7363 0.333252 11.3333 0.930206 11.3333 1.66659V2.99992H12.6666C16.3485 2.99992 19.3333 5.98469 19.3333 9.66658C19.3333 10.403 18.7363 10.9999 18 10.9999C17.2636 10.9999 16.6666 10.403 16.6666 9.66658C16.6666 7.45745 14.8758 5.66658 12.6666 5.66658H11.3333V13.6666H12.6666C16.3485 13.6666 19.3333 16.6514 19.3333 20.3333C19.3333 24.0151 16.3485 26.9999 12.6666 26.9999H11.3333V28.3333C11.3333 29.0696 10.7363 29.6666 9.99996 29.6666C9.26358 29.6666 8.66663 29.0696 8.66663 28.3333V26.9999H7.33329C3.65139 26.9999 0.666626 24.0151 0.666626 20.3333C0.666626 19.5969 1.26358 18.9999 1.99996 18.9999C2.73634 18.9999 3.33329 19.5969 3.33329 20.3333C3.33329 22.5424 5.12415 24.3333 7.33329 24.3333H8.66663V16.3333H7.33329C3.65139 16.3333 0.666626 13.3485 0.666626 9.66658C0.666626 5.98469 3.65139 2.99992 7.33329 2.99992H8.66663V1.66659C8.66663 0.930206 9.26358 0.333252 9.99996 0.333252ZM8.66663 5.66658H7.33329C5.12415 5.66658 3.33329 7.45745 3.33329 9.66658C3.33329 11.8757 5.12415 13.6666 7.33329 13.6666H8.66663V5.66658ZM11.3333 16.3333V24.3333H12.6666C14.8758 24.3333 16.6666 22.5424 16.6666 20.3333C16.6666 18.1241 14.8758 16.3333 12.6666 16.3333H11.3333Z"
        fill={fill}
        className={customInnerClassNames}
      />
    </svg>
  );
};

DollarIconGrey.defaultProps = {
  width: 20,
  height: 30
};

export default DollarIconGrey;
