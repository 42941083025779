import React from 'react';

const ArrowRightWithCircle = ({
  width = 16,
  height = 16,
  customClassNames,
  fill = '#1B1B18'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={customClassNames}>
      <g clipPath="url(#clip0_376_19412)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00033 1.99996C4.68662 1.99996 2.00033 4.68625 2.00033 7.99996C2.00033 11.3137 4.68662 14 8.00033 14C11.314 14 14.0003 11.3137 14.0003 7.99996C14.0003 4.68625 11.314 1.99996 8.00033 1.99996ZM0.666992 7.99996C0.666992 3.94987 3.95024 0.666626 8.00033 0.666626C12.0504 0.666626 15.3337 3.94987 15.3337 7.99996C15.3337 12.05 12.0504 15.3333 8.00033 15.3333C3.95024 15.3333 0.666992 12.05 0.666992 7.99996ZM7.52892 5.8047C7.26857 5.54435 7.26857 5.12224 7.52892 4.86189C7.78927 4.60154 8.21138 4.60154 8.47173 4.86189L11.1384 7.52855C11.3987 7.7889 11.3987 8.21101 11.1384 8.47136L8.47173 11.138C8.21138 11.3984 7.78927 11.3984 7.52892 11.138C7.26857 10.8777 7.26857 10.4556 7.52892 10.1952L9.05752 8.66663H5.33366C4.96547 8.66663 4.66699 8.36815 4.66699 7.99996C4.66699 7.63177 4.96547 7.33329 5.33366 7.33329H9.05752L7.52892 5.8047Z"
          fill={fill}
          fillOpacity="0.65"
        />
      </g>
      <defs>
        <clipPath id="clip0_376_19412">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRightWithCircle;
