import React from 'react';

function LockUnlocked01({ width, height, customClassNames, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`c-LockUnlocked01 ${customClassNames}`}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6 8a6 6 0 0111.5-2.4 1 1 0 01-1.832.8A4.001 4.001 0 008 8v1.002C8.238 9 8.49 9 8.759 9h6.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 011.748 1.748c.247.485.346 1.002.392 1.564.044.541.044 1.206.044 2.01v1.483c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 01-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H8.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 01-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C3 17.71 3 17.046 3 16.242v-1.483c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 011.748-1.748c.262-.134.533-.224.816-.286V8zm.911 3.038c-.438.035-.663.1-.819.18a2 2 0 00-.874.874c-.08.156-.145.38-.18.819C5 13.361 5 13.943 5 14.8v1.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.819.18C7.361 20 7.943 20 8.8 20h6.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 00.874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889v-1.4c0-.857 0-1.439-.038-1.889-.035-.438-.1-.663-.18-.819a2 2 0 00-.874-.874c-.156-.08-.38-.145-.819-.18C16.639 11 16.057 11 15.2 11H8.8c-.857 0-1.439 0-1.889.038zM12 13.5a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z"
        clipRule="evenodd"></path>
    </svg>
  );
}

LockUnlocked01.defaultProps = {
  customClassNames: '',
  fill: '#1B1B18',
  width: '24',
  height: '24'
};

export default LockUnlocked01;
