import React from 'react';

const PencilEdit = ({ customClassNames, fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={`c-WavingHand ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.862 3.5288C13.8587 2.53207 15.4747 2.53207 16.4714 3.5288C17.4682 4.52553 17.4682 6.14155 16.4714 7.13827L14.4732 9.13652C14.4726 9.13711 14.472 9.13769 14.4714 9.13827C14.4708 9.13886 14.4703 9.13944 14.4697 9.14002L7.66723 15.9425C7.65605 15.9536 7.64498 15.9647 7.63399 15.9757C7.47073 16.1393 7.32671 16.2835 7.1556 16.3963C7.00516 16.4955 6.842 16.574 6.67056 16.6295C6.47555 16.6926 6.27295 16.7149 6.04326 16.7403C6.02781 16.742 6.01224 16.7437 5.99654 16.7454L3.74029 16.9961C3.53901 17.0185 3.33847 16.9481 3.19527 16.8049C3.05206 16.6617 2.98172 16.4612 3.00408 16.2599L3.25478 14.0037C3.25652 13.988 3.25824 13.9724 3.25994 13.9569C3.28528 13.7273 3.30763 13.5246 3.37075 13.3296C3.42626 13.1582 3.50467 12.995 3.60388 12.8446C3.71671 12.6735 3.86096 12.5295 4.02449 12.3662C4.03549 12.3552 4.04658 12.3442 4.05775 12.333L12.862 3.5288ZM11.3334 6.94301L5.00056 13.2758C4.78556 13.4908 4.74564 13.5352 4.71698 13.5786C4.68391 13.6288 4.65777 13.6832 4.63927 13.7403C4.62324 13.7898 4.61353 13.8487 4.57995 14.1509L4.42129 15.5789L5.8493 15.4203C6.15149 15.3867 6.21037 15.377 6.25991 15.3609C6.31706 15.3424 6.37144 15.3163 6.42159 15.2832C6.46505 15.2546 6.50941 15.2147 6.72442 14.9996L13.0572 8.66687L11.3334 6.94301ZM14 7.72406L12.2762 6.0002L13.8048 4.47161C14.2808 3.99558 15.0526 3.99558 15.5286 4.47161C16.0046 4.94764 16.0046 5.71943 15.5286 6.19546L14 7.72406Z"
        fill={fill}
      />
    </svg>
  );
};

PencilEdit.defaultProps = {
  fill: '#1B1B18',
  with: '20',
  height: '20',
  customClassNames: ''
};

export default PencilEdit;
