import React from 'react';

const Filter = ({ customClassNames, fill = '#1B1B18' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={customClassNames}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33334 3.99998C1.33334 3.63179 1.63182 3.33331 2.00001 3.33331H14C14.3682 3.33331 14.6667 3.63179 14.6667 3.99998C14.6667 4.36817 14.3682 4.66665 14 4.66665H2.00001C1.63182 4.66665 1.33334 4.36817 1.33334 3.99998ZM3.33334 7.99998C3.33334 7.63179 3.63182 7.33331 4.00001 7.33331H12C12.3682 7.33331 12.6667 7.63179 12.6667 7.99998C12.6667 8.36817 12.3682 8.66665 12 8.66665H4.00001C3.63182 8.66665 3.33334 8.36817 3.33334 7.99998ZM5.33334 12C5.33334 11.6318 5.63182 11.3333 6.00001 11.3333H10C10.3682 11.3333 10.6667 11.6318 10.6667 12C10.6667 12.3682 10.3682 12.6666 10 12.6666H6.00001C5.63182 12.6666 5.33334 12.3682 5.33334 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default Filter;
