import React from 'react';

const SlashCircle01 = ({ width = 24, height = 24, fill = '#1b1b18' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.968 6.382a9 9 0 0012.65 12.65L4.968 6.382zm1.414-1.414l12.65 12.65a9 9 0 00-12.65-12.65zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default SlashCircle01;
