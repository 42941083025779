import React from 'react';

const Search = ({
  customClassName,
  height = '16',
  width = '16',
  fill = '#1B1B18'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={customClassName}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33301 2.66659C4.75568 2.66659 2.66634 4.75592 2.66634 7.33325C2.66634 9.91058 4.75568 11.9999 7.33301 11.9999C8.5903 11.9999 9.73147 11.5027 10.5706 10.6942C10.5881 10.6714 10.6074 10.6494 10.6283 10.6285C10.6492 10.6076 10.6711 10.5884 10.6939 10.5708C11.5025 9.73172 11.9997 8.59055 11.9997 7.33325C11.9997 4.75592 9.91034 2.66659 7.33301 2.66659ZM12.0209 11.0784C12.842 10.0519 13.333 8.74993 13.333 7.33325C13.333 4.01954 10.6467 1.33325 7.33301 1.33325C4.0193 1.33325 1.33301 4.01954 1.33301 7.33325C1.33301 10.647 4.0193 13.3333 7.33301 13.3333C8.74968 13.3333 10.0517 12.8423 11.0781 12.0212L13.5283 14.4713C13.7886 14.7317 14.2107 14.7317 14.4711 14.4713C14.7314 14.211 14.7314 13.7889 14.4711 13.5285L12.0209 11.0784Z"
        fill={fill}
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default Search;
