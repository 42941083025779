import React from 'react';

const Mail01 = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#706F6B"
        fillRule="evenodd"
        d="M5.07 2.25h7.861c.604 0 1.102 0 1.508.033.421.035.809.109 1.173.294a3 3 0 011.311 1.311c.18.351.254.724.29 1.129a.75.75 0 01.023.375c.014.338.014.728.014 1.177v4.862c0 .604 0 1.102-.033 1.508-.034.421-.108.809-.294 1.173a3 3 0 01-1.311 1.311c-.364.185-.752.26-1.173.294-.406.033-.904.033-1.508.033H5.07c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.109-1.173-.294a3 3 0 01-1.311-1.311c-.185-.364-.26-.752-.294-1.173C.75 12.533.75 12.035.75 11.43V6.569c0-.449 0-.84.014-1.177a.75.75 0 01.023-.375c.036-.405.111-.778.29-1.129a3 3 0 011.311-1.311c.364-.185.752-.26 1.173-.294.406-.033.904-.033 1.508-.033zM2.25 6.69v4.71c0 .642 0 1.08.028 1.417.027.329.076.497.136.614a1.5 1.5 0 00.655.655c.117.06.286.109.614.136.338.027.775.028 1.417.028h7.8c.643 0 1.08 0 1.417-.028.329-.027.497-.076.614-.136a1.5 1.5 0 00.656-.655c.06-.117.108-.285.135-.614.027-.338.028-.775.028-1.417V6.69l-4.944 3.461-.086.06c-.409.287-.767.54-1.176.641a2.25 2.25 0 01-1.088 0c-.409-.102-.767-.354-1.176-.64l-.086-.061-4.944-3.46zm13.438-1.787l-5.742 4.02c-.546.382-.662.448-.765.474a.75.75 0 01-.362 0c-.103-.026-.218-.092-.765-.475L2.312 4.903c.027-.155.062-.256.102-.334a1.5 1.5 0 01.655-.656c.117-.06.286-.108.614-.135.338-.027.775-.028 1.417-.028h7.8c.643 0 1.08 0 1.417.028.329.027.497.076.614.135a1.5 1.5 0 01.656.656c.04.078.074.179.101.334z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Mail01;
