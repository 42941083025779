import React from 'react';

const BarChart10 = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#946800"
        fillRule="evenodd"
        d="M10.982 3.008A6.141 6.141 0 0010.55 3h-2.1a6.143 6.143 0 00-.441.009l-.001.01C8 3.105 8 3.227 8 3.45V15h3V3.45a6.175 6.175 0 00-.009-.441.95.95 0 00-.01-.001zM12.5 7.5V3.426c0-.19 0-.374-.013-.53a1.517 1.517 0 00-.15-.577 1.5 1.5 0 00-.656-.656 1.517 1.517 0 00-.577-.15c-.156-.013-.34-.013-.53-.013H8.426c-.19 0-.374 0-.53.013a1.516 1.516 0 00-.577.15 1.5 1.5 0 00-.656.656 1.516 1.516 0 00-.15.577c-.013.156-.013.34-.013.53V4.5H3.926c-.19 0-.374 0-.53.013a1.516 1.516 0 00-.577.15 1.5 1.5 0 00-.656.656 1.516 1.516 0 00-.15.577C2 6.052 2 6.236 2 6.426v8.148c0 .19 0 .374.013.53.014.171.047.373.15.577a1.5 1.5 0 00.656.656c.204.103.406.136.577.15.156.013.34.013.53.013h11.148c.19 0 .374 0 .53-.013.171-.014.373-.047.577-.15a1.5 1.5 0 00.655-.656c.104-.204.137-.406.151-.577.013-.156.013-.34.013-.53V9.426c0-.19 0-.374-.013-.53a1.517 1.517 0 00-.15-.577 1.5 1.5 0 00-.656-.656 1.517 1.517 0 00-.577-.15c-.156-.013-.34-.013-.53-.013H12.5zm0 1.5v6h2.55a6.173 6.173 0 00.441-.009l.001-.01c.007-.087.008-.209.008-.431v-5.1a6.175 6.175 0 00-.009-.441l-.01-.001A6.141 6.141 0 0015.05 9H12.5zm-6-3H3.95a6.143 6.143 0 00-.441.009l-.001.01c-.007.087-.008.209-.008.431v8.1a6.141 6.141 0 00.009.441A6.175 6.175 0 003.95 15H6.5V6zM2.819 16.337z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default BarChart10;
