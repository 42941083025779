import React from 'react';

const WhatsappColored = ({ width = 32, height = 32 }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 33 32">
      <g
        fillRule="evenodd"
        clipPath="url(#clip0_651_31842)"
        clipRule="evenodd">
        <path
          fill="#fff"
          d="M12.745 9.515c-.285-.632-.586-.645-.857-.656-.212-.009-.453-.009-.695-.009h-.035c-.255 0-.667.095-1.017.475l-.074.08c-.397.42-1.26 1.337-1.26 3.085 0 1.855 1.348 3.647 1.553 3.92l.004.004.081.118c.487.72 2.848 4.204 6.428 5.61 2.91 1.143 3.725 1.047 4.37.971l.204-.022c.698-.063 2.254-.918 2.572-1.803.318-.887.318-1.646.222-1.805-.077-.127-.256-.213-.522-.341l-.208-.101c-.381-.19-2.255-1.108-2.604-1.235-.35-.126-.604-.19-.858.19s-.984 1.234-1.206 1.487c-.223.254-.445.286-.826.096a5.706 5.706 0 00-.253-.114 9.858 9.858 0 01-2.812-1.77c-1.133-1.005-1.898-2.247-2.12-2.627-.2-.343-.042-.53.117-.715l.05-.06c.309-.373.826-1.044.953-1.297.127-.253.063-.475-.032-.665-.04-.08-.198-.468-.393-.944-.26-.64-.587-1.442-.781-1.872z"></path>
        <path
          fill="#28D146"
          d="M9.64 26.46l.463.273a12.701 12.701 0 006.453 1.759h.005c6.986 0 12.673-5.661 12.676-12.619a12.514 12.514 0 00-3.71-8.927 12.623 12.623 0 00-8.962-3.701c-6.992 0-12.679 5.66-12.682 12.618a12.544 12.544 0 001.939 6.715l.301.478-1.28 4.656 4.797-1.253zm2.248-17.6c.271.01.572.023.857.655.195.43.521 1.231.782 1.872.195.476.353.864.393.944.095.19.159.412.032.665-.127.253-.644.924-.953 1.298l-.05.059c-.159.186-.318.372-.117.715.222.38.987 1.622 2.12 2.627a9.858 9.858 0 002.812 1.77c.105.046.19.083.253.114.38.19.603.158.826-.096.222-.253.952-1.107 1.206-1.486.254-.38.508-.317.858-.19.349.126 2.223 1.044 2.604 1.234.074.037.143.07.208.1.266.129.445.215.522.342.096.16.096.918-.222 1.805-.318.886-1.874 1.74-2.572 1.803-.068.006-.134.014-.203.022-.646.076-1.461.172-4.37-.971-3.581-1.406-5.942-4.89-6.429-5.61-.04-.058-.067-.099-.082-.118l-.003-.005c-.205-.271-1.553-2.064-1.553-3.92 0-1.747.863-2.663 1.26-3.084l.074-.08c.35-.38.762-.475 1.017-.475h.035c.242 0 .483 0 .695.01z"></path>
        <path
          fill="#F3F3F2"
          d="M1.184 32a.679.679 0 01-.66-.86l2.09-7.597a15.824 15.824 0 01-1.986-7.681C.632 7.115 7.78 0 16.564 0a15.875 15.875 0 0111.273 4.652A15.734 15.734 0 0132.5 15.874c-.004 8.747-7.153 15.863-15.936 15.863h-.007a16.025 16.025 0 01-7.374-1.803l-7.826 2.043a.69.69 0 01-.173.023zM11.888 8.86c.271.01.572.023.857.655.195.43.521 1.231.782 1.872.195.476.353.864.393.944.095.19.159.412.032.665-.127.253-.644.924-.953 1.298l-.05.059c-.159.186-.318.372-.117.715.222.38.987 1.622 2.12 2.627a9.858 9.858 0 002.812 1.77c.105.046.19.083.253.114.38.19.603.158.826-.096.222-.253.952-1.107 1.206-1.486.254-.38.508-.317.858-.19.349.126 2.223 1.044 2.604 1.234.074.037.143.07.208.1.266.129.445.215.522.342.096.16.096.918-.222 1.805-.318.886-1.874 1.74-2.572 1.803-.068.006-.134.014-.203.022-.646.076-1.461.172-4.37-.971-3.581-1.406-5.942-4.89-6.429-5.61-.04-.058-.067-.099-.082-.118l-.003-.005c-.205-.271-1.553-2.064-1.553-3.92 0-1.747.863-2.663 1.26-3.084l.074-.08c.35-.38.762-.475 1.017-.475h.035c.242 0 .483 0 .695.01zM3.344 23.451L1.18 31.319l8.086-2.111a15.293 15.293 0 007.288 1.848h.007c8.405 0 15.248-6.811 15.252-15.182a15.057 15.057 0 00-4.464-10.74A15.189 15.189 0 0016.561.68C8.153.68 1.31 7.49 1.308 15.86a15.104 15.104 0 002.036 7.591z"></path>
        <path
          fill="#fff"
          d="M1.18 31.319l2.164-7.867a15.104 15.104 0 01-2.036-7.59C1.312 7.49 8.153.68 16.561.68c4.08.001 7.909 1.582 10.788 4.452a15.057 15.057 0 014.464 10.741c-.004 8.37-6.847 15.182-15.252 15.182h-.007a15.293 15.293 0 01-7.288-1.848l-8.086 2.11zm8.923-4.586l-.463-.274-4.798 1.253 1.281-4.656-.301-.478a12.544 12.544 0 01-1.939-6.715c.003-6.958 5.69-12.618 12.682-12.618a12.623 12.623 0 018.963 3.7 12.514 12.514 0 013.71 8.928c-.004 6.958-5.69 12.619-12.677 12.619h-.005a12.701 12.701 0 01-6.453-1.76z"></path>
      </g>
      <defs>
        <clipPath id="clip0_651_31842">
          <path
            fill="#fff"
            d="M0 0H32V32H0z"
            transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappColored;
