import React from 'react';

const HappyFace = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#1B1B18"
        fillOpacity="0.5"
        fillRule="evenodd"
        d="M12 3a9 9 0 100 18 9 9 0 000-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm6.5-3a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm-7 5a1 1 0 011-1h9a1 1 0 011 1c0 1.225-.716 2.359-1.678 3.148A6.055 6.055 0 0112 18.5a6.056 6.056 0 01-3.822-1.352C7.216 16.358 6.5 15.225 6.5 14zm2.375 1c.145.203.334.407.572.602A4.056 4.056 0 0012 16.5c1.007 0 1.91-.37 2.553-.898.238-.195.427-.4.572-.602h-6.25z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default HappyFace;
