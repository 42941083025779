import React from 'react';

const NPLTrendUp = ({ customClassNames, customInnerClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={customClassNames}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C14.4477 8 14 7.55228 14 7C14 6.44772 14.4477 6 15 6H22C22.5523 6 23 6.44772 23 7V14C23 14.5523 22.5523 15 22 15C21.4477 15 21 14.5523 21 14V9.41421L14.8159 15.5983C14.6375 15.7768 14.4637 15.9506 14.3045 16.0858C14.1296 16.2342 13.9078 16.3938 13.618 16.4879C13.2163 16.6184 12.7837 16.6184 12.382 16.4879C12.0922 16.3938 11.8704 16.2342 11.6955 16.0858C11.5363 15.9506 11.3625 15.7768 11.1841 15.5983L8.42426 12.8385C8.21459 12.6288 8.09931 12.5146 8.01013 12.4389C8.00658 12.4359 8.00321 12.4331 8 12.4304C7.99679 12.4331 7.99342 12.4359 7.98987 12.4389C7.90069 12.5146 7.78541 12.6288 7.57574 12.8385L2.70711 17.7071C2.31658 18.0976 1.68342 18.0976 1.29289 17.7071C0.902369 17.3166 0.902369 16.6834 1.29289 16.2929L6.16152 11.4243C6.16904 11.4167 6.17656 11.4092 6.18406 11.4017C6.36251 11.2232 6.53629 11.0494 6.69551 10.9142C6.87036 10.7658 7.09225 10.6062 7.38197 10.5121C7.78365 10.3816 8.21635 10.3816 8.61803 10.5121C8.90775 10.6062 9.12964 10.7658 9.30449 10.9142C9.46371 11.0494 9.63749 11.2232 9.81594 11.4017L12.5757 14.1615C12.7854 14.3712 12.9007 14.4854 12.9899 14.5611C12.9934 14.5641 12.9968 14.5669 13 14.5696C13.0032 14.5669 13.0066 14.5641 13.0101 14.5611C13.0993 14.4854 13.2146 14.3712 13.4243 14.1615L19.5858 8H15Z"
        fill="#1B1B18"
        className={customInnerClassNames}
      />
    </svg>
  );
};

export default NPLTrendUp;
