import React from 'react';

const CursorBox = ({ customClassNames, customInnerClassNames }) => {
  return (
    <svg
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className={`c-CursorBox ${customClassNames}`}>
      <path
        fill="#CE2C31"
        fillRule="evenodd"
        d="M6.319 1.5h6.362c.604 0 1.102 0 1.508.033.421.035.809.109 1.173.294a3 3 0 011.311 1.311c.186.364.26.752.294 1.173.033.406.033.904.033 1.508v1.306a.75.75 0 01-1.5 0V5.85c0-.642 0-1.08-.028-1.417-.027-.329-.076-.497-.136-.614a1.5 1.5 0 00-.655-.656c-.117-.06-.285-.108-.614-.135C13.729 3.001 13.292 3 12.65 3h-6.3c-.642 0-1.08 0-1.417.028-.329.027-.497.076-.614.135a1.5 1.5 0 00-.656.656c-.06.117-.108.285-.135.614-.027.338-.028.775-.028 1.417v6.3c0 .642 0 1.08.028 1.417.027.329.076.497.135.614a1.5 1.5 0 00.656.655c.117.06.285.109.614.136.338.027.775.028 1.417.028h1.275a.75.75 0 010 1.5H6.319c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.108-1.173-.294a3 3 0 01-1.311-1.311c-.185-.364-.26-.752-.294-1.173C2 13.283 2 12.785 2 12.18V5.819c0-.604 0-1.102.033-1.508.035-.421.109-.809.294-1.173a3 3 0 011.311-1.311c.364-.185.752-.26 1.173-.294.406-.033.904-.033 1.508-.033zm3.775 6.405l.025.008 6.196 2.052c.185.061.37.122.514.185.132.057.394.179.56.448.186.302.219.675.087 1.006-.116.294-.353.46-.473.538-.131.087-.302.179-.474.271l-2.35 1.266-1.266 2.35c-.092.172-.184.343-.271.474-.079.12-.245.357-.538.473-.33.132-.704.1-1.006-.088-.27-.165-.391-.427-.448-.559a6.617 6.617 0 01-.185-.514L8.413 9.619l-.008-.025a5.726 5.726 0 01-.14-.466 1.168 1.168 0 01.016-.666c.114-.318.363-.567.68-.68.274-.099.53-.05.667-.018.14.033.306.088.466.141zm-.163 1.526l1.84 5.556 1.108-2.06.007-.01a1.125 1.125 0 01.319-.397c.08-.065.168-.112.211-.135l.012-.006 2.059-1.108L9.93 9.43z"
        clipRule="evenodd"
        className={customInnerClassNames}></path>
    </svg>
  );
};

export default CursorBox;
