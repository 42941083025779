import classnames from 'classnames';
import PropTypes from 'prop-types';

const SentFirst1Icon = ({ width, height, fill, customClassNames }) => {
  const className = 'c-SentFirst1Icon';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      className={classnames(className, customClassNames)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1734 1.48223C16.3107 1.44233 16.5871 1.37062 16.8839 1.46978C17.2193 1.58181 17.4825 1.84499 17.5945 2.18035C17.6937 2.47717 17.6219 2.75352 17.582 2.89091C17.5385 3.04063 17.4689 3.21907 17.399 3.3981L12.4359 16.116C12.3578 16.3161 12.2822 16.5099 12.2083 16.6595C12.1443 16.7888 12.0021 17.0635 11.7069 17.2169C11.3813 17.386 10.9938 17.3858 10.6684 17.2163C10.3734 17.0626 10.2315 16.7876 10.1677 16.6583C10.0939 16.5086 10.0185 16.3147 9.94069 16.1145L7.98323 11.081L2.9498 9.12359C2.74958 9.04576 2.55567 8.97038 2.40597 8.89658C2.27663 8.83281 2.00171 8.69087 1.848 8.39585C1.67849 8.0705 1.67826 7.68294 1.84739 7.35739C2.00075 7.06218 2.2755 6.91992 2.40477 6.856C2.55438 6.78203 2.7482 6.70642 2.94833 6.62835L15.6662 1.6653C15.8452 1.5954 16.0236 1.52573 16.1734 1.48223ZM15.1885 4.93646L9.43901 10.6859L11.1888 15.1855L15.1885 4.93646ZM8.37835 9.62526L14.1278 3.8758L3.87878 7.87542L8.37835 9.62526ZM17.0005 17.2516C16.838 17.2516 16.7037 17.1984 16.5977 17.0919C16.4912 16.9859 16.438 16.8516 16.438 16.6891V13.8766H15.8755C15.713 13.8766 15.5787 13.8234 15.4727 13.7169C15.3662 13.6109 15.313 13.4766 15.313 13.3141C15.313 13.1516 15.3662 13.0171 15.4727 12.9106C15.5787 12.8046 15.713 12.7516 15.8755 12.7516H16.813C17.0255 12.7516 17.2037 12.8234 17.3477 12.9669C17.4912 13.1109 17.563 13.2891 17.563 13.5016V16.6891C17.563 16.8516 17.51 16.9859 17.404 17.0919C17.2975 17.1984 17.163 17.2516 17.0005 17.2516Z"
        fill={fill}
      />
    </svg>
  );
};

SentFirst1Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  customClassNames: PropTypes.string
};
SentFirst1Icon.defaultProps = {
  width: '19',
  height: '19',
  fill: '#18774C',
  customClassNames: ''
};

export default SentFirst1Icon;
