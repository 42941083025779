import React from 'react';

const Globe02 = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2851_13917)">
        <path
          fill="#1B1B18"
          fillRule="evenodd"
          d="M2.149 6.667a6.02 6.02 0 000 2.666h2.626a10.866 10.866 0 01-.108-1.347c.01-.444.045-.884.108-1.32H2.149zm.475-1.334h2.429a10.868 10.868 0 011.43-3.14 6.014 6.014 0 00-3.86 3.14zM8 2.367a9.533 9.533 0 00-1.557 2.966h3.114A9.533 9.533 0 008 2.367zm1.876 4.3H6.124A9.533 9.533 0 006 8c.01.45.052.895.124 1.333h3.752C9.948 8.895 9.99 8.45 10 8a9.532 9.532 0 00-.124-1.333zm1.35 2.666a10.866 10.866 0 00.107-1.347c-.009-.444-.045-.884-.108-1.32h2.626a6.02 6.02 0 010 2.667h-2.626zm-1.669 1.334H6.443A9.534 9.534 0 008 13.633a9.534 9.534 0 001.557-2.966zm-3.073 3.14a10.869 10.869 0 01-1.431-3.14h-2.43a6.014 6.014 0 003.86 3.14zm3.032 0a10.866 10.866 0 001.431-3.14h2.43a6.014 6.014 0 01-3.86 3.14zm3.86-8.474h-2.429a10.866 10.866 0 00-1.43-3.14 6.014 6.014 0 013.86 3.14zM.666 8a7.333 7.333 0 1114.667 0A7.333 7.333 0 01.667 8z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="clip0_2851_13917">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Globe02;
