import React from 'react';

const Play = () => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#F5F5F5"
        d="M4.559 2.598a.833.833 0 00-1.226.735v13.334a.833.833 0 001.226.735l12.5-6.667a.833.833 0 000-1.47l-12.5-6.667z"></path>
    </svg>
  );
};

export default Play;
