import React from 'react';

const SearchRefraction = () => {
  return (
    <svg width="40" height="40" fill="none" viewBox="0 0 40 40">
      <path
        fill="#C8C7C1"
        fillRule="evenodd"
        d="M18.333 6.667c-6.443 0-11.666 5.224-11.666 11.667s5.223 11.667 11.666 11.667c3.144 0 5.996-1.243 8.094-3.265a1.694 1.694 0 01.309-.308A11.628 11.628 0 0030 18.334c0-6.443-5.223-11.667-11.667-11.667zm11.72 21.03a14.937 14.937 0 003.28-9.363c0-8.284-6.715-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 3.542 0 6.797-1.228 9.363-3.28l6.125 6.125a1.667 1.667 0 002.357-2.357l-6.125-6.125zM16.667 10c0-.92.746-1.667 1.666-1.667 5.523 0 10 4.477 10 10a1.667 1.667 0 11-3.333 0 6.667 6.667 0 00-6.667-6.667c-.92 0-1.666-.746-1.666-1.666z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default SearchRefraction;
