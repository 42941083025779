import ChatIntegrationGoldFeatureIcon from '@/components/common/Icons/ChatIntegrationGoldFeatureIcon';
import ContentLibraryGoldIcon from '@/components/common/Icons/ContentLibraryGoldIcon';
import FileUploadIcon from '@/components/common/Icons/FileUploadIcon/FileUploadIcon';
import LiveEventsFeatureGoldIcon from '@/components/common/Icons/LiveEventsFeatureGoldIcon';
import MagicReachFeatureIcon from '@/components/common/Icons/MagicReachFeatureIcon';
import MonetizationFeatureGoldIcon from '@/components/common/Icons/MonetizationFeatureGoldIcon';
import VisitIcon from '@/components/common/Icons/VisitIcon/VisitIcon';
import Web3FeatureIcon from '@/components/common/Icons/Web3FeatureIcon';
import AccountBalanceWallet from '@/components/common/IconsV2/AccountBalanceWallet';
import AddLink from '@/components/common/IconsV2/AddLink';
import AlertCircle from '@/components/common/IconsV2/AlertCircle';
import AnalyticsStroke from '@/components/common/IconsV2/AnalyticsStroke';
import ArrowForward from '@/components/common/IconsV2/ArrowForward';
import BookOpen from '@/components/common/IconsV2/BookOpen';
import Cached from '@/components/common/IconsV2/Cached';
import CalendarDays from '@/components/common/IconsV2/CalendarDays';
import CalendarEvent from '@/components/common/IconsV2/CalendarEvent/CalendarEvent';
import CalendarPlus01 from '@/components/common/IconsV2/CalendarPlus01';
import CameraIcon from '@/components/common/IconsV2/CameraIcon';
import CheckCircle from '@/components/common/IconsV2/CheckCircle';
import CheckIcon from '@/components/common/IconsV2/CheckIcon';
import Clock from '@/components/common/IconsV2/Clock/Clock';
import CollectionsBookmark from '@/components/common/IconsV2/CollectionsBookmark';
import CopyContentIcon from '@/components/common/IconsV2/CopyContentIcon';
import CopyContentV2 from '@/components/common/IconsV2/CopyContentV2/CopyContent';
import CopyLink from '@/components/common/IconsV2/CopyLinkIcon';
import Dash from '@/components/common/IconsV2/Dash';
import DeleteOutline from '@/components/common/IconsV2/DeleteOutline';
import DollarIconGrey from '@/components/common/IconsV2/DollarIconGrey';
import DragIndicatorReorder from '@/components/common/IconsV2/DragIndicatorReorder';
import EditDocument from '@/components/common/IconsV2/EditDocument';
import Exercise from '@/components/common/IconsV2/Exercise/Exercise';
import FeaturePlay from '@/components/common/IconsV2/FeaturePlay';
import File04 from '@/components/common/IconsV2/File04';
import FileUpload from '@/components/common/IconsV2/FileUpload';
import FormsAddOn from '@/components/common/IconsV2/FormsAddOn';
import GiftCard from '@/components/common/IconsV2/GiftCard';
import Globe from '@/components/common/IconsV2/Globe/Globe';
import HourGlassIcon from '@/components/common/IconsV2/HourGlassIcon';
import Hub from '@/components/common/IconsV2/Hub';
import IconLLeft from '@/components/common/IconsV2/IconLLeft';
import IconLRight from '@/components/common/IconsV2/IconLRight';
import IconNE from '@/components/common/IconsV2/IconNE';
import IconSLeft from '@/components/common/IconsV2/IconSLeft';
import InfoCircleIcon from '@/components/common/IconsV2/InfoCircleIcon';
import InfoCircleOutlineIcon from '@/components/common/IconsV2/InfoCircleOutlineIcon';
import InfoQuestion from '@/components/common/IconsV2/InfoQuestion';
import LibraryAdd from '@/components/common/IconsV2/LibraryAdd';
import LibraryStroke from '@/components/common/IconsV2/LibraryStroke';
import Link from '@/components/common/IconsV2/Link';
import LiveSession from '@/components/common/IconsV2/LiveSession';
import LiveTV from '@/components/common/IconsV2/LiveTV';
import LockIcon from '@/components/common/IconsV2/LockIcon';
import MagicReach from '@/components/common/IconsV2/MagicReach';
import MinusCircleFill from '@/components/common/IconsV2/MinusCircleFill';
import MoreVerticle from '@/components/common/IconsV2/MoreVerticle';
import NPLCourse from '@/components/common/IconsV2/NPLCourse';
import NPLTrendUp from '@/components/common/IconsV2/NPLTrendUp';
import People from '@/components/common/IconsV2/People';
import Place from '@/components/common/IconsV2/Place/Place';
import PlayOverlayVideo from '@/components/common/IconsV2/PlayOverlayVideo';
import PlusIcon from '@/components/common/IconsV2/PlusIcon';
import QandA from '@/components/common/IconsV2/QandA';
import Readings from '@/components/common/IconsV2/Readings';
import SentimentDissatisfied from '@/components/common/IconsV2/SentimentSatisfied';
import SmartWebsite from '@/components/common/IconsV2/SmartWebsite';
import SupportAgent from '@/components/common/IconsV2/SupportAgent';
import TokenNftWeb3 from '@/components/common/IconsV2/TokenNftWeb3';
import UnlockIcon from '@/components/common/IconsV2/UnlockIcon';
import User01 from '@/components/common/IconsV2/User01/User01';
import UsersCheck from '@/components/common/IconsV2/UsersCheck';
import VideoLibrary from '@/components/common/IconsV2/VideoLibrary/VideoLibrary';
import Warning from '@/components/common/IconsV2/Warning';
import Website from '@/components/common/IconsV2/Website';
import WebsiteStroke from '@/components/common/IconsV2/WebsiteStroke';
import ZoomSessionLive from '@/components/common/IconsV2/ZoomSessionLive/ZoomSessionLive';
import Add from './collection/Add';
import Announcement from './collection/Announcement';
import Announcement03 from './collection/Announcement03';
import Archive from './collection/Archive';
import ArrowLeftIcon from './collection/ArrowLeft';
import ArrowUpward from './collection/ArrowUpward';
import Assistant from './collection/Assistant';
import BarChart10 from './collection/BarChart10';
import BarChart12 from './collection/BarChart12';
import Bell01 from './collection/Bell01';
import Calendar from './collection/Calendar';
import Certificate01 from './collection/Certificate01';
import CheckCircleFilled from './collection/CheckCircleFilled';
import ChevronDown from './collection/ChevronDown';
import ChevronRight from './collection/ChevronRight';
import Close from './collection/Close';
import Copy02 from './collection/Copy02';
import CopyClip from './collection/CopyClip';
import CubeOutline from './collection/CubeOutline';
import CurrencyDollarCircle from './collection/CurrencyDollarCircle';
import CursorBox from './collection/CursorBox';
import Delete from './collection/Delete';
import Download from './collection/Download';
import EditPencil from './collection/EditPencil';
import ErrorAlertWarning from './collection/ErrorAlertWarning';
import Eye from './collection/Eye';
import EyeFirstIcon from './collection/EyeFirst';
import EyeSecondIcon from './collection/EyeSecond';
import File from './collection/File';
import UsersAdd from './collection/UsersAdd';
import FileAttachment04 from './collection/FileAttachment04';
import Globe02 from './collection/Globe02';
import HappyFace from './collection/HappyFace';
import HomeSmile from './collection/HomeSmile';
import Info from './collection/Info';
import Like01 from './collection/Like01';
import Link03 from './collection/Link03';
import Lock from './collection/Lock';
import Mail01 from './collection/Mail01';
import MarkerPin01 from './collection/MarkerPin01';
import MeatBallMenu from './collection/MeatBallMenu';
import MessageChatCircle from './collection/MessageChatCircle';
import MessageHeartCircle from './collection/MessageHeartCircle';
import MessageTextSquare02 from './collection/MessageTextSquare02';
import Play from './collection/Play';
import PublicPage from './collection/PublicPage';
import Publish from './collection/Publish';
import Rows01 from './collection/Rows01';
import Search from './collection/Search';
import SendSecond1Icon from './collection/SendSecond1';
import SentFirst1Icon from './collection/SentFirst1';
import Settings01 from './collection/Settings01';
import Share from './collection/Share';
import Share04 from './collection/Share04';
import ShoppingBag01 from './collection/ShoppingBag01';
import Sort from './collection/Sort';
import Star07 from './collection/Star07';
import StarFilled2Icon from './collection/StarFilled2';
import StarOutline from './collection/StarOutline';
import Tick from './collection/Tick';
import Trash01 from './collection/Trash01';
import TriangleShadowMask from './collection/TriangleShadowMask';
import Users01 from './collection/Users01';
import UsersMoney from './collection/UsersMoney';
import WavingHand from './collection/WavingHand';
import WhatsappColored from './collection/WhatsappColored';
import WhatsappGrey from './collection/WhatsappGrey';
import Reorder from './collection/Reorder';
import Filter from './collection/Filter';
import PlayFilled from './collection/PlayFilled';
import CoinHand from './collection/CoinHand';
import ArrowNarrowUpRight from './collection/ArrowNarrowUpRight';
import CalendarMoney from '@/components/common/IconsV2/CalendarMoney';
import UserSquare from '@/components/common/IconsV2/UserSquare';
import Sale03 from './collection/Sale03';
import HeartHand from './collection/HeartHand';
import Courses from './collection/Courses';
import CreditCardShield from './collection/CreditCardShield';
import Send01 from '@/components/common/IconsV2/Send01';
import Percent02 from './collection/Percent02';
import Tag01 from './collection/Tag01';
import SearchRefraction from './collection/SearchRefraction';
import SlashCircle01 from './collection/SlashCircle01';
import PlusCircle from './collection/PlusCircle';
import PencilEdit from './collection/PencilEdit';
import ArrowRightWithCircle from './collection/ArrowRightWithCircle';
import SendMagic01 from './collection/SendMagic01';
import LightBulb02 from './collection/LightBulb02';
import Star06 from './collection/Star06';
import SendMagic03 from './collection/SendMagic03';
import MessageXSquare from './collection/MessageXSquare';
import StarsFilled01 from './collection/StarsFilled01';
import LockUnlocked01 from './collection/LockUnlocked01';

// Future V2 -> this map should be automated
export const iconMap = {
  'message-chat-circle': MessageChatCircle,
  file: File,
  archive: Archive,
  eye: Eye,
  publish: Publish,
  sort: Sort,
  info: Info,
  share: Share,
  tick: Tick,
  'meatball-menu': MeatBallMenu,
  'arrow-left': ArrowLeftIcon,
  lock: Lock,
  search: Search,
  delete: Delete,
  'edit-pencil': EditPencil,
  'users-money': UsersMoney,
  'certificate-01': Certificate01,
  'shopping-bag-01': ShoppingBag01,
  'mail-01': Mail01,
  'copy-clip': CopyClip,
  download: Download,
  announcement: Announcement,
  'cursor-box': CursorBox,
  play: Play,
  'bar-chat-10': BarChart10,
  'cube-outline': CubeOutline,
  'like-01': Like01,
  'message-text-square-02': MessageTextSquare02,
  'happy-face': HappyFace,
  'trash-01': Trash01,
  'chevron-down': ChevronDown,
  'star-outline': StarOutline,
  add: Add,
  close: Close,
  money: DollarIconGrey,
  'users-check': UsersCheck,
  video: NPLCourse,
  'trend-up': NPLTrendUp,
  'user-01': User01,
  'message-heart-circle': MessageHeartCircle,
  'calendar-plus-01': CalendarPlus01,
  'arrow-upward': ArrowUpward,
  'chevron-right': ChevronRight,
  'link-03': Link03,
  'file-attachment-04': FileAttachment04,
  'whatsapp-colored': WhatsappColored,
  'whatsapp-grey': WhatsappGrey,
  'error-alert-warning': ErrorAlertWarning,
  'waving-hand': WavingHand,
  'check-circle-filled': CheckCircleFilled,
  'share-04': Share04,
  people: People,
  place: Place,
  pencil: PencilEdit,
  'plus-circle': PlusCircle,
  'video-library': VideoLibrary,
  'zoom-session-live': ZoomSessionLive,
  exercise: Exercise,
  'calendar-event': CalendarEvent,
  website: Website,
  'account-balance-wallet': AccountBalanceWallet,
  readings: Readings,
  'q-and-a': QandA,
  'card-giftcard': GiftCard,
  'minus-circle-fill': MinusCircleFill,
  clock: Clock,
  globe: Globe,
  'coin-hand': CoinHand,
  'live-session': LiveSession,
  'collections-bookmark': CollectionsBookmark,
  'delete-outline': DeleteOutline,
  'more-vertical': MoreVerticle,
  'file-upload': FileUpload,
  'file-upload-icon': FileUploadIcon,
  'file-04': File04,
  'edit-document': EditDocument,
  'library-add': LibraryAdd,
  'add-link': AddLink,
  'forms-add-on': FormsAddOn,
  'drag-indicator-reorder': DragIndicatorReorder,
  warning: Warning,
  cached: Cached,
  'check-circle': CheckCircle,
  link: Link,
  camera: CameraIcon,
  'lock-open': UnlockIcon,
  'lock-closed': LockIcon,
  'live-tv': LiveTV,
  'support-agent': SupportAgent,
  'arrow-forward': ArrowForward,
  'icon-l-right': IconLRight,
  'play-video-overlay': PlayOverlayVideo,
  'smart-website': SmartWebsite,
  'icon-l-left': IconLLeft,
  'feature-play': FeaturePlay,
  'sentiment-dissatisfied': SentimentDissatisfied,
  'icon-ne': IconNE,
  'calendar-days': CalendarDays,
  'library-stroke': LibraryStroke,
  'magic-reach': MagicReach,
  hub: Hub,
  'website-stroke': WebsiteStroke,
  'analytics-stoke': AnalyticsStroke,
  'token-nft-web3': TokenNftWeb3,
  'icon-s-left': IconSLeft,
  'external-link': VisitIcon,
  'copy-link': CopyLink,
  plus: PlusIcon,
  'info-question': InfoQuestion,
  'info-circle': InfoCircleIcon,
  'info-circle-outline': InfoCircleOutlineIcon,
  'book-open': BookOpen,
  'copy-content': CopyContentIcon,
  'copy-page': CopyContentV2,
  dash: Dash,
  'web3-gold': Web3FeatureIcon,
  'chat-integration-gold': ChatIntegrationGoldFeatureIcon,
  'magic-reach-gold': MagicReachFeatureIcon,
  'events-feature-gold': LiveEventsFeatureGoldIcon,
  'content-library-feature-gold': ContentLibraryGoldIcon,
  'monetization-feature-gold': MonetizationFeatureGoldIcon,
  'alert-circle': AlertCircle,
  'hour-glass': HourGlassIcon,
  check: CheckIcon,
  'star-filled-02': StarFilled2Icon,
  'globe-02': Globe02,
  'marker-pin-01': MarkerPin01,
  'copy-02': Copy02,
  reorder: Reorder,
  'users-add': UsersAdd,
  'triangle-shadow-mask': TriangleShadowMask,
  'star-07': Star07,
  'home-smile': HomeSmile,
  'announcement-03': Announcement03,
  calendar: Calendar,
  'rows-01': Rows01,
  'currency-dollar-circle': CurrencyDollarCircle,
  'users-01': Users01,
  'bar-chart-12': BarChart12,
  assistant: Assistant,
  'public-page': PublicPage,
  'settings-01': Settings01,
  'bell-01': Bell01,
  filter: Filter,
  'arrow-narrow-up-right': ArrowNarrowUpRight,
  'send-first-01': SentFirst1Icon,
  'send-second-01': SendSecond1Icon,
  'eye-first': EyeFirstIcon,
  'eye-second': EyeSecondIcon,
  'play-filled': PlayFilled,
  'calendar-money': CalendarMoney,
  'user-square': UserSquare,
  'sale-03': Sale03,
  'heart-hand': HeartHand,
  courses: Courses,
  'credit-card-shield': CreditCardShield,
  'send-01': Send01,
  'percent-02': Percent02,
  'tag-01': Tag01,
  'search-refraction': SearchRefraction,
  'slash-circle-01': SlashCircle01,
  'arrow-right-circle': ArrowRightWithCircle,
  'send-magic-01': SendMagic01,
  'lightbulb-02': LightBulb02,
  'star-06': Star06,
  'send-magic-03': SendMagic03,
  'message-x-square': MessageXSquare,
  'stars-filled-01': StarsFilled01,
  'lock-unlocked-01': LockUnlocked01
};
