import React from 'react';

const Info = ({ customClassNames, fill, width, height, fillOpacity }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={customClassNames}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 2.66659C5.05464 2.66659 2.66683 5.0544 2.66683 7.99992C2.66683 10.9454 5.05464 13.3333 8.00016 13.3333C10.9457 13.3333 13.3335 10.9454 13.3335 7.99992C13.3335 5.0544 10.9457 2.66659 8.00016 2.66659ZM1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992ZM7.30029 5.33325C7.30029 4.96506 7.59877 4.66659 7.96696 4.66659H8.03363C8.40182 4.66659 8.70029 4.96506 8.70029 5.33325V5.39992C8.70029 5.76811 8.40182 6.06659 8.03363 6.06659H7.96696C7.59877 6.06659 7.30029 5.76811 7.30029 5.39992V5.33325ZM8.00016 6.66659C8.36835 6.66659 8.66683 6.96506 8.66683 7.33325V10.6666C8.66683 11.0348 8.36835 11.3333 8.00016 11.3333C7.63197 11.3333 7.3335 11.0348 7.3335 10.6666V7.33325C7.3335 6.96506 7.63197 6.66659 8.00016 6.66659Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

Info.defaultProps = {
  width: 24,
  height: 24,
  fill: '#1B1B18',
  customClassNames: '',
  fillOpacity: '0.5'
};

export default Info;
