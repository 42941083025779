import classnames from 'classnames';
import PropTypes from 'prop-types';

const StarFilled2Icon = ({ fill, width, height, customClassNames }) => {
  const className = 'c-StarFilled2Icon';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={classnames(className, customClassNames)}>
      <g clipPath="url(#clip0_2097_18188)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 0.5C2.52614 0.5 2.75 0.723858 2.75 1V1.75H3.5C3.77614 1.75 4 1.97386 4 2.25C4 2.52614 3.77614 2.75 3.5 2.75H2.75V3.5C2.75 3.77614 2.52614 4 2.25 4C1.97386 4 1.75 3.77614 1.75 3.5V2.75H1C0.723858 2.75 0.5 2.52614 0.5 2.25C0.5 1.97386 0.723858 1.75 1 1.75H1.75V1C1.75 0.723858 1.97386 0.5 2.25 0.5ZM6.5 1C6.70689 1 6.89241 1.12741 6.96667 1.32051L7.83376 3.57494C7.98396 3.96546 8.03116 4.078 8.09572 4.1688C8.1605 4.2599 8.2401 4.3395 8.3312 4.40428C8.422 4.46884 8.53454 4.51604 8.92506 4.66624L11.1795 5.53333C11.3726 5.60759 11.5 5.79311 11.5 6C11.5 6.20689 11.3726 6.39241 11.1795 6.46667L8.92506 7.33376C8.53454 7.48396 8.422 7.53116 8.3312 7.59572C8.2401 7.6605 8.1605 7.7401 8.09572 7.8312C8.03116 7.922 7.98396 8.03454 7.83376 8.42506L6.96667 10.6795C6.89241 10.8726 6.70689 11 6.5 11C6.29312 11 6.1076 10.8726 6.03333 10.6795L5.16624 8.42506C5.01604 8.03454 4.96884 7.922 4.90428 7.8312C4.8395 7.7401 4.7599 7.6605 4.6688 7.59572C4.578 7.53116 4.46546 7.48396 4.07494 7.33376L1.82051 6.46667C1.62741 6.39241 1.5 6.20689 1.5 6C1.5 5.79311 1.62741 5.60759 1.82051 5.53333L4.07494 4.66624C4.46546 4.51604 4.578 4.46884 4.6688 4.40428C4.7599 4.3395 4.8395 4.2599 4.90428 4.1688C4.96884 4.078 5.01604 3.96546 5.16624 3.57494L6.03333 1.32051C6.10759 1.12741 6.29311 1 6.5 1ZM2.25 8C2.52614 8 2.75 8.22386 2.75 8.5V9.25H3.5C3.77614 9.25 4 9.47386 4 9.75C4 10.0261 3.77614 10.25 3.5 10.25H2.75V11C2.75 11.2761 2.52614 11.5 2.25 11.5C1.97386 11.5 1.75 11.2761 1.75 11V10.25H1C0.723858 10.25 0.5 10.0261 0.5 9.75C0.5 9.47386 0.723858 9.25 1 9.25H1.75V8.5C1.75 8.22386 1.97386 8 2.25 8Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2097_18188">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

StarFilled2Icon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};
StarFilled2Icon.defaultProps = {
  width: '21',
  height: '21',
  fill: '#946800',
  customClassNames: ''
};

export default StarFilled2Icon;
