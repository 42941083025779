import React from 'react';

const Certificate01 = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#18774C"
        fillRule="evenodd"
        d="M1.5 3A2.25 2.25 0 013.75.75h10.5A2.25 2.25 0 0116.5 3v10.5a2.25 2.25 0 01-2.25 2.25h-.9l-1.425 1.425a.75.75 0 01-1.06 0l-1.857-1.856-1.856 1.856a.75.75 0 01-1.06 0L4.665 15.75H3.75A2.25 2.25 0 011.5 13.5V3zm4.06 11.523l1.062 1.06.946-.946a3.014 3.014 0 01-1.098-1.024l-.91.91zm.44-2.56l-2.03 2.03a.75.75 0 00-.168.257H3.75A.75.75 0 013 13.5V3a.75.75 0 01.75-.75h10.5A.75.75 0 0115 3v10.5a.75.75 0 01-.75.75h-.036a.75.75 0 00-.168-.257L12 11.946a3 3 0 00-6 .016zm1.51.212v-.005a1.5 1.5 0 111.5 1.33h-.013a1.5 1.5 0 01-1.487-1.325zm2.932 2.456c.446-.245.822-.6 1.094-1.027l.92.92-1.061 1.06-.953-.953zM6 4.5a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5A.75.75 0 016 4.5zM4.5 7.125a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Certificate01;
