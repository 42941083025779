import React from 'react';

const CubeOutline = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#3442B2"
        fillRule="evenodd"
        d="M9.198.889a1.5 1.5 0 01.604 0c.23.047.436.162.6.254l.045.025-.364.656.364-.656 1.105.614a.75.75 0 11-.729 1.311L9.718 2.48a4.91 4.91 0 00-.218-.117l-.004.001c-.043.021-.101.053-.215.116l-1.104.614a.75.75 0 11-.729-1.311l1.105-.614.044-.025c.164-.092.37-.207.6-.254zm4.709 3.06a.75.75 0 011.02-.292l1.07.594.047.027c.12.066.262.144.393.246a.748.748 0 01.539.915c.025.164.024.326.024.463v1.223a.75.75 0 01-1.5 0v-.6l-.573.318a.75.75 0 11-.729-1.311l.507-.282-.507-.282a.75.75 0 01-.291-1.02zm-8.814 0a.75.75 0 01-.291 1.02l-.508.28.508.283a.75.75 0 01-.729 1.311L3.5 6.525v.6a.75.75 0 11-1.5 0V5.956v-.054c0-.137-.001-.299.024-.463a.747.747 0 01.539-.915c.13-.102.272-.18.392-.246l.048-.027 1.07-.594a.75.75 0 011.02.291zm2.064 3.75a.75.75 0 011.02-.292l1.323.735 1.323-.735a.75.75 0 11.729 1.311l-1.302.723v1.434a.75.75 0 11-1.5 0V9.44l-1.302-.723a.75.75 0 01-.291-1.02zM2.75 10.124a.75.75 0 01.75.75v1.169c0 .137 0 .207.003.258v.005l.004.002c.043.027.104.062.224.128l-.323.582.323-.582 1.07.595a.75.75 0 01-.728 1.311l-1.07-.595.364-.655-.364.655a6.343 6.343 0 00-.048-.026c-.173-.095-.39-.215-.558-.397a1.5 1.5 0 01-.321-.546c-.078-.234-.077-.483-.076-.681v-1.224a.75.75 0 01.75-.75zm13.5 0a.75.75 0 01.75.75v1.223c0 .198.002.447-.076.681a1.498 1.498 0 01-.321.546c-.168.182-.386.302-.559.397a5.611 5.611 0 00-.047.027l-1.07.594a.75.75 0 11-.729-1.311l1.07-.595c.12-.066.181-.1.224-.128l.004-.002v-.005c.003-.05.004-.12.004-.258v-1.17a.75.75 0 01.75-.75zm-6.75 3.75a.75.75 0 01.75.75v.6l.573-.318a.75.75 0 01.729 1.311l-1.105.614-.045.025a2.924 2.924 0 01-.392.193.748.748 0 01-1.02 0 2.916 2.916 0 01-.437-.218l-1.105-.614a.75.75 0 01.729-1.311l.573.318v-.6a.75.75 0 01.75-.75z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default CubeOutline;
