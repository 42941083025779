import React from 'react';

const Assistant = ({ fill = '#91918D' }) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={fill}
        d="M15.833 1.25H4.167C3.25 1.25 2.5 2 2.5 2.917v11.666c0 .917.75 1.667 1.667 1.667H7.5l2.5 2.5 2.5-2.5h3.333c.917 0 1.667-.75 1.667-1.667V2.917c0-.917-.75-1.667-1.667-1.667zm0 13.333h-4.025L10 16.392l-1.808-1.809H4.167V2.917h11.666v11.666zM10 13.75l1.567-3.433L15 8.75l-3.433-1.567L10 3.75 8.433 7.183 5 8.75l3.433 1.567L10 13.75z"></path>
    </svg>
  );
};

export default Assistant;
