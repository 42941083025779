import React from 'react';

const Copy02 = ({ width = 16, height = 16, fill = '#1B1B18' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2851_13922)">
        <path
          fill={fill}
          fillOpacity="1"
          fillRule="evenodd"
          d="M3.441.667h5.118c.351 0 .655 0 .904.02.264.022.526.07.778.198a2 2 0 01.874.874c.129.252.176.514.198.778.02.25.02.553.02.904v1.226h1.226c.352 0 .655 0 .904.02.264.022.526.07.778.198a2 2 0 01.874.874c.129.252.176.514.198.778.02.25.02.553.02.904v5.118c0 .352 0 .655-.02.904-.022.264-.07.526-.198.778a2 2 0 01-.874.874 2.038 2.038 0 01-.778.198c-.25.02-.552.02-.904.02H7.441c-.351 0-.655 0-.904-.02a2.038 2.038 0 01-.778-.198 2 2 0 01-.874-.874 2.036 2.036 0 01-.198-.778c-.02-.25-.02-.552-.02-.904v-1.226H3.44c-.351 0-.655 0-.904-.02a2.038 2.038 0 01-.778-.198 2 2 0 01-.874-.874 2.036 2.036 0 01-.198-.778c-.02-.25-.02-.553-.02-.904V3.441c0-.351 0-.655.02-.904.022-.264.07-.526.198-.778a2 2 0 01.874-.874c.252-.129.514-.176.778-.198.25-.02.553-.02.904-.02zM6 12.533c0 .385 0 .633.016.822.015.18.04.248.057.281a.667.667 0 00.291.291c.033.017.1.042.281.057.19.015.437.016.822.016h5.066c.385 0 .633 0 .822-.016.18-.015.248-.04.281-.057a.667.667 0 00.291-.291c.017-.033.042-.1.057-.281.015-.19.016-.437.016-.822V7.467c0-.385 0-.633-.016-.822-.015-.18-.04-.248-.057-.281a.667.667 0 00-.291-.291c-.033-.017-.1-.042-.281-.057A11.338 11.338 0 0012.533 6H7.467c-.385 0-.633 0-.822.016-.18.015-.248.04-.281.057a.667.667 0 00-.291.291c-.017.033-.042.1-.057.281-.015.19-.016.437-.016.822v5.066zm4-7.866H7.441c-.351 0-.655 0-.904.02-.264.022-.526.07-.778.198a2 2 0 00-.874.874 2.036 2.036 0 00-.198.778c-.02.25-.02.553-.02.904V10h-1.2c-.385 0-.633 0-.822-.016-.18-.015-.248-.04-.281-.057a.667.667 0 01-.291-.291c-.017-.033-.042-.1-.057-.281A11.338 11.338 0 012 8.533V3.467c0-.385 0-.633.016-.822.015-.18.04-.248.057-.281a.667.667 0 01.291-.291c.033-.017.1-.042.281-.057.19-.015.437-.016.822-.016h5.066c.385 0 .633 0 .822.016.18.015.248.04.281.057a.667.667 0 01.291.291c.017.033.042.1.057.281.015.19.016.437.016.822v1.2z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="clip0_2851_13922">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Copy02;
