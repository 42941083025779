import React from 'react';

const Percent02 = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#1B1B18"
        fillOpacity="0.5"
        fillRule="evenodd"
        d="M4.667 4a.667.667 0 100 1.333.667.667 0 000-1.333zm-2 .667a2 2 0 114 0 2 2 0 01-4 0zm10.471-1.805c.26.26.26.682 0 .943l-9.333 9.333a.667.667 0 01-.943-.943l9.333-9.333c.26-.26.683-.26.943 0zm-1.805 7.805a.667.667 0 100 1.333.667.667 0 000-1.333zm-2 .666a2 2 0 114 0 2 2 0 01-4 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Percent02;
