import React from 'react';

const ArrowUpward = ({ customClassNames, fill }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={customClassNames}>
      <path
        d="M6.49998 9.39562V3.81062L8.93998 6.25062C9.13498 6.44562 9.45498 6.44562 9.64998 6.25062C9.84498 6.05562 9.84498 5.74062 9.64998 5.54562L6.35498 2.25062C6.15999 2.05562 5.84499 2.05562 5.64999 2.25062L2.34999 5.54062C2.15499 5.73562 2.15499 6.05062 2.34999 6.24562C2.54499 6.44062 2.85999 6.44062 3.05499 6.24562L5.49999 3.81062V9.39562C5.49999 9.67062 5.72499 9.89562 5.99999 9.89562C6.27499 9.89562 6.49998 9.67062 6.49998 9.39562Z"
        fill={fill}
      />
    </svg>
  );
};

ArrowUpward.defaultProps = {
  fill: '#30A66D'
};

export default ArrowUpward;
