import classNames from 'classnames';
import React from 'react';

const Like01 = ({
  width = 16,
  height = 16,
  fill = '#1b1b18',
  stroke,
  customClassNames = ''
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      className={classNames('c-Like01', customClassNames)}>
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth={stroke ? 1.5 : 0}
        d="M14.202 7.858L8 14.06 1.798 7.858a3.578 3.578 0 015.06-5.06l.612.612.53.53.53-.53.612-.612a3.578 3.578 0 015.06 5.06z"></path>
    </svg>
  );
};

export default Like01;
