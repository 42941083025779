import React from 'react';

const ShoppingBag01 = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#CD1D8D"
        fillRule="evenodd"
        d="M5.054.75h7.892c.17 0 .384-.001.591.058a1.5 1.5 0 01.495.247c.17.13.299.302.4.438l.028.037 1.186 1.581.032.043.192.256.03.04c.149.198.28.373.376.57.084.175.145.359.182.549.042.215.042.434.042.68v8.881c0 .394 0 .736-.023 1.016-.024.297-.078.592-.222.876a2.25 2.25 0 01-.983.983c-.284.145-.58.198-.876.222-.28.023-.622.023-1.017.023H4.621c-.395 0-.736 0-1.017-.023-.297-.024-.592-.077-.875-.222a2.25 2.25 0 01-.984-.983c-.144-.284-.198-.579-.222-.875-.023-.281-.023-.623-.023-1.018V5.3v-.05c0-.247 0-.466.042-.681a2.25 2.25 0 01.182-.548c.096-.198.227-.373.376-.57l.03-.04.193-.258.031-.042.014-.019.002-.002 1.17-1.56.028-.037c.101-.136.23-.308.4-.438a1.5 1.5 0 01.495-.247c.207-.06.42-.058.591-.058zM4.74 2.43l-.99 1.32h10.5l-.99-1.32a4.807 4.807 0 00-.134-.174l-.002-.003h-.004a4.78 4.78 0 00-.22-.003H5.1a4.781 4.781 0 00-.224.003l-.002.003c-.027.033-.063.08-.134.174zM15 5.242a7.07 7.07 0 01-.424.008H3.424c-.145 0-.294 0-.424-.008V14.1c0 .433 0 .712.018.924.017.204.045.28.064.317a.75.75 0 00.328.328c.037.018.112.047.316.063.212.018.492.018.924.018h8.7c.433 0 .712 0 .924-.018.204-.016.28-.045.317-.063a.75.75 0 00.327-.328c.02-.037.048-.113.064-.317.018-.212.018-.491.018-.924V5.3v-.058zM6 6.75a.75.75 0 01.75.75 2.25 2.25 0 104.5 0 .75.75 0 011.5 0 3.75 3.75 0 11-7.5 0A.75.75 0 016 6.75z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default ShoppingBag01;
