import React from 'react';

const Reorder = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.33657 6.94912C2.24324 8.66245 3.44324 10.1024 5.04991 10.4024L4.52324 9.87578C4.26324 9.61578 4.26324 9.19578 4.52324 8.93578C4.78324 8.67578 5.20324 8.67578 5.46324 8.93578L7.18991 10.6625C7.44991 10.9225 7.44991 11.3425 7.18991 11.6025L5.46991 13.3358C5.20991 13.5958 4.78991 13.5958 4.52991 13.3358C4.26991 13.0758 4.26991 12.6558 4.52991 12.3958L5.11658 11.8091V11.7691C2.68991 11.4825 0.829908 9.33578 1.01657 6.78912C1.18991 4.32245 3.36324 2.46912 5.83658 2.46912H6.99658C7.36324 2.46912 7.66324 2.76912 7.66324 3.13578C7.66324 3.50245 7.36324 3.80245 6.99658 3.80245H5.80991C4.00991 3.80245 2.42991 5.16245 2.33657 6.94912Z"
        fill="#1B1B18"
      />
      <path
        d="M10.3299 7.13578H13.6632C14.3966 7.13578 14.9966 6.53578 14.9966 5.80245V3.80245C14.9966 3.06912 14.3966 2.46912 13.6632 2.46912H10.3299C9.59658 2.46912 8.99658 3.06912 8.99658 3.80245V5.80245C8.99658 6.53578 9.59658 7.13578 10.3299 7.13578ZM13.6632 5.80245H10.3299V3.80245H13.6632V5.80245Z"
        fill="#1B1B18"
      />
      <path
        d="M13.6632 13.1358H10.3299C9.59658 13.1358 8.99658 12.5358 8.99658 11.8025V9.80245C8.99658 9.06912 9.59658 8.46912 10.3299 8.46912H13.6632C14.3966 8.46912 14.9966 9.06912 14.9966 9.80245V11.8025C14.9966 12.5358 14.3966 13.1358 13.6632 13.1358Z"
        fill="#1B1B18"
      />
    </svg>
  );
};

export default Reorder;
