import React from 'react';

const UserSquare = ({ width, height, fill, customClassNames }) => {
  return (
    <svg
      className={`c-UserSquare ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18">
      <g clipPath="url(#clip0_11288_53725)">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M5.069.75h7.862c.604 0 1.102 0 1.508.033.421.035.809.109 1.173.294a3 3 0 011.311 1.311c.186.364.26.752.294 1.173.033.406.033.904.033 1.508v7.862c0 .604 0 1.102-.033 1.508-.035.421-.108.809-.294 1.173a3 3 0 01-1.311 1.311 2.4 2.4 0 01-.395.158c-.56.17-1.271.17-2.192.169h-8.05c-.92 0-1.632 0-2.192-.17a2.401 2.401 0 01-.395-.157 3 3 0 01-1.311-1.311c-.185-.364-.26-.752-.294-1.173C.75 14.033.75 13.535.75 12.93V5.069c0-.604 0-1.102.033-1.508.035-.421.109-.809.294-1.173a3 3 0 011.311-1.311c.364-.185.752-.26 1.173-.294C3.967.75 4.465.75 5.07.75zM3.683 2.278c-.329.027-.497.076-.614.135a1.5 1.5 0 00-.656.656c-.06.117-.108.285-.135.614-.027.338-.028.775-.028 1.417v7.8c0 .642 0 1.08.028 1.417.02.238.05.392.089.504a3.75 3.75 0 012.901-2.749C5.632 12 6.05 12 6.663 12H11.337c.613 0 1.031 0 1.395.072a3.75 3.75 0 012.901 2.749c.038-.112.07-.266.089-.504.027-.338.028-.775.028-1.417V5.1c0-.642 0-1.08-.028-1.417-.027-.329-.076-.497-.136-.614a1.5 1.5 0 00-.655-.656c-.117-.06-.285-.108-.614-.135-.338-.027-.775-.028-1.417-.028H5.1c-.642 0-1.08 0-1.417.028zm10.56 13.45a2.512 2.512 0 00-.036-.417 2.25 2.25 0 00-1.768-1.768c-.2-.04-.456-.043-1.189-.043h-4.5c-.733 0-.99.004-1.189.043a2.25 2.25 0 00-1.768 1.768c-.02.102-.031.22-.037.417.305.02.725.022 1.344.022h7.8c.62 0 1.039-.003 1.344-.022zM9 4.875a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.75 2.25a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="clip0_11288_53725">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

UserSquare.defaultProps = {
  fill: '#1B1B18',
  width: '18',
  height: '18',
  customClassNames: ''
};

export default UserSquare;
