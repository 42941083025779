import React from 'react';

const Calendar = ({ fill, width, height }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 20">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.667.833c.46 0 .833.373.833.834V2.5h5v-.833a.833.833 0 011.666 0V2.5c.397.003.743.011 1.044.036.468.038.899.12 1.303.326.627.32 1.137.83 1.457 1.457.206.404.288.835.326 1.303.037.451.037 1.005.037 1.676v7.069c0 .67 0 1.224-.037 1.675-.038.469-.12.9-.326 1.304a3.334 3.334 0 01-1.457 1.456c-.404.206-.835.288-1.303.327-.451.037-1.005.037-1.676.037H6.465c-.67 0-1.224 0-1.675-.037-.468-.038-.899-.12-1.303-.327a3.333 3.333 0 01-1.457-1.456c-.206-.405-.288-.835-.327-1.304-.037-.45-.037-1.004-.036-1.675v-7.07c0-.67 0-1.224.036-1.675.039-.468.12-.899.327-1.303.32-.627.83-1.137 1.457-1.457.404-.206.835-.288 1.303-.326.3-.025.647-.033 1.043-.036v-.834c0-.46.373-.834.833-.834zm-.834 3.335c-.371.003-.663.01-.907.03-.366.03-.552.084-.683.15-.313.16-.568.415-.728.729-.067.13-.12.317-.15.682-.031.375-.032.86-.032 1.574V7.5h13.334v-.167c0-.714-.001-1.199-.032-1.574-.03-.365-.084-.552-.15-.682a1.666 1.666 0 00-.728-.729c-.13-.066-.318-.12-.683-.15a12.63 12.63 0 00-.908-.03V5A.833.833 0 1112.5 5v-.833h-5V5a.833.833 0 01-1.667 0v-.832zm10.834 4.999H3.332v5.166c0 .714 0 1.2.031 1.574.03.366.084.553.15.683.16.314.416.569.73.728.13.067.316.12.682.15.375.031.86.032 1.574.032h7c.714 0 1.199 0 1.574-.031.365-.03.552-.084.683-.15.313-.16.568-.415.728-.73.066-.13.12-.316.15-.682.03-.375.031-.86.031-1.574V9.167z"
        clipRule="evenodd"></path>
    </svg>
  );
};

Calendar.defaultProps = {
  fill: '#91918D',
  width: 20,
  height: 20
};

export default Calendar;
