const EditPencil = ({ width, height, fill, customClassNames }) => {
  return (
    <svg
      className={`c-EditPencil ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M8 20v1a1 1 0 00.707-.293L8 20zm-4 0H3a1 1 0 001 1v-1zm0-4l-.707-.707A1 1 0 003 16h1zM15.293 4.707l.707.707-.707-.707zm1.414 0L16 5.414l.707-.707zm2.586 2.586L20 6.586l-.707.707zm0 1.414L18.586 8l.707.707zM8 19H4v2h4v-2zm-3 1v-4H3v4h2zm-.293-3.293L16 5.414 14.586 4 3.293 15.293l1.414 1.414zM16 5.414L18.586 8 20 6.586 17.414 4 16 5.414zM18.586 8L7.293 19.293l1.414 1.414L20 9.414 18.586 8zm0 0L20 9.414a2 2 0 000-2.828L18.586 8zM16 5.414L17.414 4a2 2 0 00-2.828 0L16 5.414z"></path>
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8l4 4"></path>
    </svg>
  );
};

EditPencil.defaultProps = {
  width: '24',
  height: '24',
  fill: '#75777A'
};

export default EditPencil;
