import React from 'react';

const ArrowLeftIcon = ({
  width = 18,
  height = 18,
  customClassNames,
  fill = '#1B1B18'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      className={customClassNames}>
      <path
        d="M14.0934 8.25395H5.71587L9.37587 4.59395C9.66837 4.30145 9.66837 3.82145 9.37587 3.52895C9.08337 3.23645 8.61087 3.23645 8.31837 3.52895L3.37587 8.47145C3.08337 8.76395 3.08337 9.23644 3.37587 9.52894L8.31837 14.4714C8.61087 14.7639 9.08337 14.7639 9.37587 14.4714C9.66837 14.1789 9.66837 13.7064 9.37587 13.4139L5.71587 9.75395H14.0934C14.5059 9.75395 14.8434 9.41645 14.8434 9.00395C14.8434 8.59145 14.5059 8.25395 14.0934 8.25395Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
