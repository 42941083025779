import React from 'react';

const MarkerPin01 = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#1B1B18"
        fillRule="evenodd"
        d="M8 2a4.667 4.667 0 00-4.667 4.667c0 1.261.57 2.452 1.529 3.697.81 1.052 1.846 2.08 2.96 3.185l.178.177.178-.177c1.114-1.104 2.15-2.133 2.96-3.184.96-1.246 1.529-2.437 1.529-3.698A4.667 4.667 0 008 2zM2 6.667a6 6 0 1112 0c0 1.684-.765 3.16-1.805 4.511-.87 1.13-1.983 2.232-3.094 3.335l-.63.625a.667.667 0 01-.942 0l-.63-.625c-1.111-1.103-2.224-2.206-3.094-3.335C2.765 9.827 2 8.351 2 6.667zm6-1.334A1.333 1.333 0 108 8a1.333 1.333 0 000-2.667zM5.333 6.667a2.667 2.667 0 115.334 0 2.667 2.667 0 01-5.334 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default MarkerPin01;
