import React from 'react';

const Courses = ({ customClassNames, height, width, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={customClassNames}>
      <path
        d="M8 4C7.71667 4 7.479 3.904 7.287 3.712C7.09567 3.52067 7 3.28333 7 3C7 2.71667 7.09567 2.479 7.287 2.287C7.479 2.09567 7.71667 2 8 2H16C16.2833 2 16.5207 2.09567 16.712 2.287C16.904 2.479 17 2.71667 17 3C17 3.28333 16.904 3.52067 16.712 3.712C16.5207 3.904 16.2833 4 16 4H8ZM5 7C4.71667 7 4.479 6.90433 4.287 6.713C4.09567 6.521 4 6.28333 4 6C4 5.71667 4.09567 5.479 4.287 5.287C4.479 5.09567 4.71667 5 5 5H19C19.2833 5 19.5207 5.09567 19.712 5.287C19.904 5.479 20 5.71667 20 6C20 6.28333 19.904 6.521 19.712 6.713C19.5207 6.90433 19.2833 7 19 7H5ZM4 22C3.45 22 2.97933 21.8043 2.588 21.413C2.196 21.021 2 20.55 2 20V10C2 9.45 2.196 8.979 2.588 8.587C2.97933 8.19567 3.45 8 4 8H20C20.55 8 21.021 8.19567 21.413 8.587C21.8043 8.979 22 9.45 22 10V20C22 20.55 21.8043 21.021 21.413 21.413C21.021 21.8043 20.55 22 20 22H4ZM4 20H20V10H4V20ZM11.55 17.975L14.75 15.825C15.05 15.625 15.2 15.35 15.2 15C15.2 14.65 15.05 14.375 14.75 14.175L11.55 12.025C11.2167 11.7917 10.875 11.775 10.525 11.975C10.175 12.175 10 12.475 10 12.875V17.125C10 17.525 10.175 17.825 10.525 18.025C10.875 18.225 11.2167 18.2083 11.55 17.975Z"
        fill={fill}
      />
    </svg>
  );
};

Courses.defaultProps = {
  fill: '#1B1B18',
  height: '24',
  width: '24',
  customClassNames: ''
};

export default Courses;
