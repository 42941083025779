import React from 'react';

const Tag01 = ({ width = 24, height = 24, fill = '#1B1B18' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M10.405 3.028c-.088-.022-.194-.028-.73-.028H5.2c-.577 0-.949 0-1.232.024-.272.022-.373.06-.422.085a1 1 0 00-.437.437c-.025.05-.063.15-.085.422C3 4.25 3 4.623 3 5.2v4.474c0 .537.006.643.028.731a1 1 0 00.12.289c.047.077.117.157.496.536l7.669 7.669c.606.605 1.018 1.017 1.362 1.309.336.285.54.397.707.451.402.131.834.131 1.236 0 .167-.054.371-.166.707-.451.344-.292.756-.704 1.362-1.31l2.212-2.211c.605-.606 1.017-1.018 1.309-1.362.285-.336.397-.54.451-.707.131-.402.131-.834 0-1.236-.054-.167-.166-.371-.451-.707-.292-.344-.704-.756-1.31-1.362L11.23 3.644c-.38-.38-.459-.45-.536-.497a1 1 0 00-.289-.12zM9.758 1c.407 0 .765 0 1.114.083a3 3 0 01.867.36c.306.186.559.44.846.728l.06.06 7.697 7.697c.57.57 1.04 1.039 1.39 1.453.366.43.661.865.83 1.383a4 4 0 010 2.472c-.169.518-.464.953-.83 1.383-.35.414-.82.884-1.39 1.453l-2.27 2.27c-.57.57-1.039 1.04-1.453 1.39-.43.366-.865.661-1.383.83a4 4 0 01-2.472 0c-.518-.169-.953-.464-1.383-.83-.414-.35-.884-.82-1.453-1.39L2.23 12.644l-.059-.059c-.288-.287-.542-.54-.729-.846a3 3 0 01-.36-.867C1 10.523 1 10.165 1 9.758V5.16c0-.527 0-.981.03-1.356.033-.395.104-.789.297-1.167a3 3 0 011.311-1.311c.378-.193.772-.264 1.167-.296C4.18 1 4.635 1 5.16 1H9.758zM6.5 8a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Tag01;
