import React from 'react';

const SendMagic03 = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#1B1B18"
        fillRule="evenodd"
        d="M13.312.548a.333.333 0 00-.622 0l-.393 1.021c-.1.26-.132.336-.175.396a.665.665 0 01-.157.157c-.06.043-.136.075-.396.175l-1.021.393a.333.333 0 000 .622l1.021.393c.26.1.335.131.396.174.06.043.114.097.157.157s.074.136.175.396l.393 1.022a.333.333 0 00.622 0l.393-1.022c.1-.26.131-.335.174-.396a.668.668 0 01.157-.157c.06-.043.136-.074.396-.174l1.022-.393a.333.333 0 000-.622l-1.022-.393c-.26-.1-.335-.132-.396-.175a.668.668 0 01-.157-.157c-.043-.06-.074-.136-.174-.396L13.312.548zM2.715 1.89l-.022-.01c-.155-.07-.31-.14-.441-.185-.12-.042-.363-.118-.63-.043a1 1 0 00-.663.598c-.102.259-.05.507-.022.631.032.135.086.296.14.458l.007.024 1.547 4.662-1.55 4.63a5.765 5.765 0 00-.14.46c-.03.123-.082.372.02.632a1 1 0 00.662.6c.269.074.511-.002.631-.044.131-.045.287-.115.443-.186l11.185-5.033c.152-.068.305-.137.424-.204.11-.062.324-.191.446-.437a1 1 0 000-.887 1.049 1.049 0 00-.446-.437 5.647 5.647 0 00-.424-.204L2.715 1.891zm1.092 5.443L2.445 3.232 13.043 8 2.45 12.768l1.373-4.1H7a.667.667 0 000-1.334H3.807z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default SendMagic03;
