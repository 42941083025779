import React from 'react';

const MessageTextSquare02 = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#1B1B18"
        fillOpacity="0.65"
        fillRule="evenodd"
        d="M5.173 1.333h5.655c.536 0 .98 0 1.34.03.375.03.72.096 1.043.26.502.257.91.665 1.165 1.166.165.324.23.668.261 1.043.03.36.03.804.03 1.34v3.655c0 .537 0 .98-.03 1.34-.03.376-.096.72-.26 1.044-.256.501-.664.91-1.166 1.165-.324.165-.668.23-1.043.261-.36.03-.804.03-1.34.03H9.123c-.443 0-.572.003-.69.027a1.334 1.334 0 00-.338.119c-.106.054-.21.132-.555.409l-1.609 1.287c-.122.098-.247.198-.357.27-.105.07-.308.194-.573.194a1 1 0 01-.782-.376 1.042 1.042 0 01-.207-.569A5.296 5.296 0 014 13.581v-.92a3.051 3.051 0 01-.69-.085 2.667 2.667 0 01-1.886-1.886c-.091-.341-.09-.735-.09-1.265V5.173c0-.537 0-.98.029-1.341.03-.375.096-.72.261-1.043.256-.501.664-.91 1.166-1.165.323-.165.668-.23 1.042-.261.361-.03.804-.03 1.34-.03zM3.94 2.692c-.292.023-.442.067-.546.12-.251.128-.455.332-.583.583-.053.104-.096.253-.12.546-.025.3-.025.688-.025 1.259v4.133c0 .663.006.864.045 1.012.124.46.483.82.943.943.148.04.349.045 1.012.045.368 0 .667.299.667.667v1.28l1.373-1.1.045-.035c.282-.226.494-.396.737-.52.214-.11.441-.189.677-.237.266-.055.539-.055.9-.055H10.8c.571 0 .96 0 1.26-.025.292-.024.441-.067.545-.12.251-.128.455-.332.583-.583.053-.104.097-.254.12-.546.025-.3.026-.688.026-1.26V5.2c0-.57-.001-.958-.025-1.258-.024-.293-.068-.442-.12-.546a1.333 1.333 0 00-.584-.583c-.104-.053-.253-.096-.546-.12-.3-.025-.688-.025-1.259-.025H5.2c-.57 0-.959 0-1.26.025zM4 5.667C4 5.298 4.298 5 4.666 5H8a.667.667 0 010 1.333H4.667A.667.667 0 014 5.667zM4 8c0-.368.298-.667.666-.667H10a.667.667 0 110 1.334H4.667A.667.667 0 014 8z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default MessageTextSquare02;
