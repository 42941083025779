import React from 'react';

const Trash01 = () => {
  return (
    <svg
      className="c-TrashIcon01"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18">
      <path
        fill="#CE2C31"
        fillRule="evenodd"
        d="M8.871.75h1.258c.395 0 .736 0 1.017.023.297.024.592.078.875.222.424.216.768.56.984.984.144.283.198.578.222.875.02.252.023.552.023.896h3a.75.75 0 010 1.5h-.75v7.681c0 .604 0 1.102-.033 1.508-.035.421-.109.809-.294 1.173a3 3 0 01-1.311 1.311c-.364.186-.752.26-1.173.294-.406.033-.904.033-1.508.033H7.819c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.108-1.173-.294a3 3 0 01-1.311-1.311c-.185-.364-.26-.752-.294-1.173-.033-.406-.033-.904-.033-1.508V5.25h-.75a.75.75 0 110-1.5h3c0-.344.002-.644.023-.896.024-.297.078-.592.222-.875A2.25 2.25 0 016.98.995c.283-.144.578-.198.875-.222C8.134.75 8.476.75 8.87.75zM5 5.25v7.65c0 .642 0 1.08.028 1.417.027.329.076.497.135.614a1.5 1.5 0 00.656.655c.117.06.285.109.614.136.338.027.775.028 1.417.028h3.3c.642 0 1.08 0 1.417-.028.329-.027.497-.076.614-.136a1.5 1.5 0 00.655-.655c.06-.117.109-.285.136-.614.027-.338.028-.775.028-1.417V5.25H5zm6.75-1.5h-4.5c0-.349.003-.587.018-.774.017-.204.045-.28.064-.316a.75.75 0 01.328-.328c.037-.02.112-.047.316-.064.212-.017.492-.018.924-.018h1.2c.432 0 .712 0 .924.018.204.017.28.045.317.064a.75.75 0 01.327.328c.02.037.047.112.064.316.015.187.018.425.018.774zM8 7.875a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0v-3.75a.75.75 0 01.75-.75zm3 0a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0v-3.75a.75.75 0 01.75-.75z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Trash01;
