import React from 'react';

const StarsFilled01 = ({ width = 12, height = 12, fill = '#FCCB1D' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 12 12">
      <g clipPath="url(#clip0_681_124167)">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M7.5.5a.5.5 0 01.467.32l.589 1.533c.15.39.197.503.262.594a1 1 0 00.235.235c.091.065.204.112.594.262l1.533.59a.5.5 0 010 .933l-1.533.589c-.39.15-.503.197-.594.262a1 1 0 00-.235.235c-.065.091-.112.204-.262.594l-.59 1.532a.5.5 0 01-.933 0l-.589-1.532c-.15-.39-.197-.503-.262-.594a1 1 0 00-.235-.235c-.091-.065-.204-.112-.594-.262l-1.532-.59a.5.5 0 010-.933l1.532-.589c.39-.15.503-.197.594-.262a1 1 0 00.235-.235c.065-.091.112-.204.262-.594L7.034.82A.5.5 0 017.5.5zM3.25 6a.5.5 0 01.447.276l.392.785c.142.282.185.364.237.431a1 1 0 00.182.182c.067.052.149.095.431.237l.785.392a.5.5 0 010 .894l-.785.392c-.282.142-.364.185-.431.237a1 1 0 00-.182.181c-.052.068-.095.15-.237.432l-.392.785a.5.5 0 01-.894 0l-.392-.785c-.142-.282-.185-.364-.237-.431a1 1 0 00-.182-.182c-.067-.052-.149-.095-.431-.237l-.785-.392a.5.5 0 010-.894l.785-.392c.282-.142.364-.185.431-.237a1 1 0 00.182-.182c.052-.067.095-.149.237-.431l.392-.785A.5.5 0 013.25 6z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="clip0_681_124167">
          <path fill="#fff" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarsFilled01;
