import React from 'react';

const Sort = ({ customClassNames, fill = '#1B1B18' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={customClassNames}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19543 2.19526C4.45577 1.93491 4.87788 1.93491 5.13823 2.19526L7.8049 4.86193C8.06525 5.12228 8.06525 5.54439 7.8049 5.80474C7.54455 6.06509 7.12244 6.06509 6.86209 5.80474L5.3335 4.27614V13.3333C5.3335 13.7015 5.03502 14 4.66683 14C4.29864 14 4.00016 13.7015 4.00016 13.3333V4.27614L2.47157 5.80474C2.21122 6.06509 1.78911 6.06509 1.52876 5.80474C1.26841 5.54439 1.26841 5.12228 1.52876 4.86193L4.19543 2.19526ZM10.6668 11.7239V2.66667C10.6668 2.29848 10.9653 2 11.3335 2C11.7017 2 12.0002 2.29848 12.0002 2.66667V11.7239L13.5288 10.1953C13.7891 9.93491 14.2112 9.93491 14.4716 10.1953C14.7319 10.4556 14.7319 10.8777 14.4716 11.1381L11.8049 13.8047C11.5446 14.0651 11.1224 14.0651 10.8621 13.8047L8.19543 11.1381C7.93508 10.8777 7.93508 10.4556 8.19543 10.1953C8.45577 9.93491 8.87788 9.93491 9.13823 10.1953L10.6668 11.7239Z"
        fill={fill}
      />
    </svg>
  );
};

export default Sort;
