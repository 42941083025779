import React from 'react';

const Star06 = ({ width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3496_29337)">
        <path
          fill="#946800"
          fillRule="evenodd"
          d="M3 .667c.368 0 .667.298.667.666v1h1a.667.667 0 010 1.334h-1v1a.667.667 0 11-1.334 0v-1h-1a.667.667 0 010-1.334h1v-1c0-.368.299-.666.667-.666zm5.666.666c.276 0 .524.17.623.428l1.156 3.006c.2.52.263.67.35.791.085.122.192.228.313.314s.271.15.792.35l3.006 1.156a.667.667 0 010 1.244L11.9 9.778c-.52.2-.67.264-.792.35a1.333 1.333 0 00-.314.314c-.086.12-.149.27-.35.791L9.29 14.24a.667.667 0 01-1.245 0l-1.156-3.006c-.2-.52-.263-.67-.35-.791a1.333 1.333 0 00-.313-.314c-.121-.086-.271-.15-.792-.35L2.427 8.622a.667.667 0 010-1.244l3.006-1.156c.52-.2.67-.264.792-.35.121-.086.228-.192.314-.314.086-.12.149-.27.35-.791L8.043 1.76a.667.667 0 01.622-.428zm0 2.524l-.533 1.388a81.53 81.53 0 00-.027.068c-.162.423-.285.743-.48 1.018a2.667 2.667 0 01-.628.628c-.276.196-.595.319-1.018.481l-.068.026L4.524 8l1.388.534.068.026c.423.162.742.285 1.018.481.242.173.455.385.628.628.195.275.318.595.48 1.018.01.022.018.045.027.068l.534 1.388.533-1.388.027-.068c.162-.423.285-.743.48-1.018.173-.243.385-.455.628-.628.276-.196.595-.319 1.018-.48l.068-.027L12.81 8l-1.388-.534-.068-.026c-.423-.162-.742-.285-1.018-.481a2.666 2.666 0 01-.628-.628c-.195-.275-.318-.595-.48-1.018-.01-.022-.018-.045-.027-.068l-.534-1.388zM3 10.667c.368 0 .667.298.667.666v1h1a.667.667 0 110 1.334h-1v1a.667.667 0 11-1.334 0v-1h-1a.667.667 0 010-1.334h1v-1c0-.368.299-.666.667-.666z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="clip0_3496_29337">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star06;
