import React from 'react';

const Bell01 = () => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="#1B1B18"
        fillOpacity="0.65"
        fillRule="evenodd"
        d="M5.2 2.033a4.667 4.667 0 017.967 3.3c0 1.942.488 3.23 1 4.052l.008.012c.238.383.426.686.553.906.064.11.122.217.165.311.021.047.045.105.064.168a.818.818 0 01.034.3.988.988 0 01-.116.407.937.937 0 01-.708.475l-.003.001c-.098.014-.21.022-.322.026-.223.009-.533.009-.918.009H4.077c-.385 0-.695 0-.918-.009a3.107 3.107 0 01-.322-.026h-.003a.937.937 0 01-.824-.884.817.817 0 01.034-.299c.019-.063.043-.12.064-.168.043-.094.101-.2.165-.311.127-.22.315-.523.553-.906l.008-.012c.512-.823 1-2.11 1-4.052 0-1.238.491-2.424 1.367-3.3zM8.5 2a3.333 3.333 0 00-3.333 3.333c0 2.179-.551 3.712-1.2 4.756-.143.228-.261.418-.358.577h9.783a92.16 92.16 0 00-.357-.577c-.65-1.044-1.201-2.577-1.201-4.756A3.333 3.333 0 008.5 2zM6.237 13.559a.667.667 0 01.94-.059c.354.312.816.5 1.323.5.508 0 .97-.188 1.323-.5a.667.667 0 01.883 1 3.324 3.324 0 01-2.206.833 3.324 3.324 0 01-2.205-.833.667.667 0 01-.058-.941z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Bell01;
