import React from 'react';

const LightBulb02 = ({ width = 24, height = 24, fill = '#706F6B' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 1a1 1 0 011 1v1a1 1 0 11-2 0V2a1 1 0 011-1zM4.193 4.193a1 1 0 011.414 0l.6.6a1 1 0 01-1.414 1.414l-.6-.6a1 1 0 010-1.414zm15.614 0a1 1 0 010 1.414l-.6.6a1 1 0 11-1.414-1.414l.6-.6a1 1 0 011.414 0zM12 7a5 5 0 100 10 5 5 0 000-10zm-7 5a7 7 0 1110 6.326V20a3 3 0 11-6 0v-1.674A7 7 0 015 12zm6 6.93V20a1 1 0 102 0v-1.07a7.062 7.062 0 01-2 0zM1 12a1 1 0 011-1h1a1 1 0 110 2H2a1 1 0 01-1-1zm19 0a1 1 0 011-1h1a1 1 0 110 2h-1a1 1 0 01-1-1z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default LightBulb02;
