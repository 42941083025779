import React from 'react';

const UsersAdd = ({ customClassNames, fill }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={`c-UsersAdd ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 4.6665C8.06709 4.6665 6.50008 6.23351 6.50008 8.1665C6.50008 10.0995 8.06709 11.6665 10.0001 11.6665C11.9331 11.6665 13.5001 10.0995 13.5001 8.1665C13.5001 6.23351 11.9331 4.6665 10.0001 4.6665ZM4.50008 8.1665C4.50008 5.12894 6.96252 2.6665 10.0001 2.6665C13.0376 2.6665 15.5001 5.12894 15.5001 8.1665C15.5001 11.2041 13.0376 13.6665 10.0001 13.6665C6.96252 13.6665 4.50008 11.2041 4.50008 8.1665ZM19.0001 14.6665C19.5524 14.6665 20.0001 15.1142 20.0001 15.6665V17.6665H22.0001C22.5524 17.6665 23.0001 18.1142 23.0001 18.6665C23.0001 19.2188 22.5524 19.6665 22.0001 19.6665H20.0001V21.6665C20.0001 22.2188 19.5524 22.6665 19.0001 22.6665C18.4478 22.6665 18.0001 22.2188 18.0001 21.6665V19.6665H16.0001C15.4478 19.6665 15.0001 19.2188 15.0001 18.6665C15.0001 18.1142 15.4478 17.6665 16.0001 17.6665H18.0001V15.6665C18.0001 15.1142 18.4478 14.6665 19.0001 14.6665ZM7.32635 15.1665C7.38341 15.1665 7.44132 15.1665 7.50009 15.1665H12.0001C12.5524 15.1665 13.0001 15.6142 13.0001 16.1665C13.0001 16.7188 12.5524 17.1665 12.0001 17.1665H7.50009C6.03177 17.1665 5.51929 17.1774 5.12923 17.2957C4.17042 17.5865 3.42011 18.3368 3.12926 19.2956C3.01094 19.6857 3.00008 20.1982 3.00008 21.6665C3.00008 22.2188 2.55237 22.6665 2.00008 22.6665C1.4478 22.6665 1.00008 22.2188 1.00008 21.6665C1.00008 21.6077 1.00006 21.5498 1.00004 21.4928C0.999587 20.2655 0.999277 19.4275 1.21538 18.7151C1.70013 17.1171 2.95065 15.8666 4.54866 15.3818C5.26106 15.1657 6.09908 15.166 7.32635 15.1665Z"
        fill={fill}
      />
    </svg>
  );
};

UsersAdd.defaultProps = {
  fill: '#3442B2',
  customClassNames: ''
};

export default UsersAdd;
