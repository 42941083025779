import React from 'react';

const TriangleShadowMask = ({ customClassNames, width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 50"
      fill="none"
      className={customClassNames}>
      <mask
        id="mask0_1806_119034"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-13"
        width="23"
        height="63">
        <path d="M0 -13H12V24H23V50H0V-13Z" fill={fill} />
      </mask>
      <g mask="url(#mask0_1806_119034)">
        <g filter="url(#filter0_dddd_1806_119034)">
          <path
            d="M4.82843 24H12V12L3.41421 20.5858C2.15428 21.8457 3.04662 24 4.82843 24Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dddd_1806_119034"
          x="-19.1758"
          y="0"
          width="53.1758"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_1806_119034"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.0941176 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1806_119034"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.0941176 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1806_119034"
            result="effect2_dropShadow_1806_119034"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.0941176 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1806_119034"
            result="effect3_dropShadow_1806_119034"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.0941176 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_1806_119034"
            result="effect4_dropShadow_1806_119034"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_1806_119034"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

TriangleShadowMask.defaultProps = {
  customClassNames: '',
  width: '23',
  height: '50',
  fill: '#fff'
};

export default TriangleShadowMask;
