import React from 'react';

const ArrowNarrowUpRight = () => {
  return (
    <svg width="32" height="32" fill="none" viewBox="0 0 32 32">
      <path
        fill="#1B1B18"
        fillOpacity="0.65"
        fillRule="evenodd"
        d="M12 8c0-.736.597-1.333 1.334-1.333H24c.737 0 1.334.597 1.334 1.333v10.667a1.333 1.333 0 01-2.667 0v-7.448L8.943 24.943a1.333 1.333 0 11-1.885-1.886L20.78 9.333h-7.447A1.333 1.333 0 0112 8z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default ArrowNarrowUpRight;
