import React from 'react';

const WhatsappGrey = ({
  width = 18,
  height = 18,
  customClassNames,
  fill = 'rgba(27, 27, 24, 0.5)'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      className={customClassNames}>
      <g clipPath="url(#clip0_1872_24824)">
        <path
          d="M11.5926 10.0603C11.3872 9.98719 11.2382 9.94894 11.0886 10.1745C10.9423 10.3944 10.5114 10.8979 10.3798 11.0447C10.2482 11.1915 10.1188 11.2028 9.89662 11.1032C9.67106 10.989 8.9505 10.7544 8.09494 9.98719C7.42669 9.39262 6.98175 8.66025 6.84956 8.43469C6.71794 8.21194 6.83494 8.08594 6.94631 7.97456C7.04869 7.87219 7.17188 7.71694 7.28606 7.58194C7.39463 7.44694 7.4295 7.35637 7.50881 7.21012C7.58194 7.05206 7.54425 6.92888 7.48856 6.8175C7.43287 6.70613 6.98456 5.60194 6.79725 5.16206C6.61837 4.72556 6.43106 4.78125 6.29325 4.78125C6.16444 4.76944 6.01481 4.76944 5.86575 4.76944C5.71669 4.76944 5.47312 4.82513 5.26781 5.03888C5.0625 5.26444 4.48256 5.80331 4.48256 6.89344C4.48256 7.98637 5.28525 9.04387 5.39662 9.20194C5.51081 9.34819 6.97556 11.5982 9.22275 12.5651C9.75881 12.7907 10.1751 12.9257 10.5002 13.0371C11.0363 13.2069 11.5256 13.1833 11.9121 13.1276C12.3396 13.0573 13.2362 12.5854 13.4241 12.0583C13.6148 11.5279 13.6148 11.0886 13.5591 10.989C13.5034 10.8866 13.3571 10.8309 13.1316 10.7314C12.931 10.6428 11.8013 10.1381 11.5926 10.0603ZM9.04669 16.3125H9.03488C7.70456 16.3125 6.38944 15.9519 5.24081 15.2781L4.97137 15.1172L2.15887 15.8496L2.91488 13.113L2.73319 12.8318C1.99039 11.6507 1.59632 10.2838 1.59637 8.88862C1.59637 4.80487 4.93931 1.47375 9.05231 1.47375C11.0447 1.47375 12.9139 2.25 14.3201 3.65625C15.0138 4.34171 15.5641 5.15845 15.9388 6.05883C16.3135 6.95921 16.5052 7.9252 16.5026 8.90044C16.497 12.9814 13.1569 16.3125 9.0495 16.3125H9.04669ZM15.3894 2.58694C13.6783 0.934312 11.4283 0 9.03488 0C4.09838 0 0.07875 4.00219 0.0759375 8.92069C0.0759375 10.4912 0.486 12.0234 1.27125 13.3796L0 18L4.752 16.7608C6.06842 17.4706 7.53988 17.8439 9.03544 17.8476H9.03825C13.9776 17.8476 17.9972 13.8454 18 8.9235C18 6.54188 17.0713 4.30031 15.3782 2.61563L15.3894 2.58694Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1872_24824">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappGrey;
